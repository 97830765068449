import React,{useContext,useState,useEffect} from 'react'
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet'
import axios from 'axios'
// import {store} from '../index';
import { Redirect } from 'react-router-dom'
import './allset.css'


const Allset = (props) => {

  if (window.screen.width < 700) {
    return <Redirect to='/mallset'  />
  }


  const handleLogout = () => {
		localStorage.removeItem("token");
		window.location.reload();
	};


//   const[token,setToken]=useContext(store)
//   const [data,setData]=useState(null)

//   useEffect(() =>{
//     axios.get('http://localhost:8090/myprofile'
//     ,{
//         headers: {
//             'x-token' : token
//         }
//     }
//     )
//     .then(res => setData(res.data)).catch((err) => console.log(err))
// },[])

  // if(!token){
  //   return <Redirect to='/sign-in1'/>
  // }


///new
// const [user, setUser] = useState(null);

// useEffect(() => {
//   // Make an API request to your server to get the user's data
//   axios.get('http://localhost:8090/api/user')
//     .then((response) => {
//       setUser(response.data);
//     })
//     .catch((error) => {
//       console.error('Error fetching user data:', error);
//     });
// }, []);
//new
  return (
    <div className="allset-container">
      <Helmet>
        <title>Allset </title>
        <meta property="og:title" content="Allset " />
      </Helmet>
      <div className="allset-container1">
        <div className="allset-container2">
          <div className="allset-left">
            <div className="allset-hometab">
              <div className="allset-logohorizontal">
                <img
                  alt="Gigglr0112I134"
                  src="/external/gigglr0112i134-7pk-200h.png"
                  className="allset-gigglr0112"
                />
                <span className="allset-text">
                  <span>gigglr</span>
                </span>
              </div>
            </div>
          </div>
        </div>
         {/* { 
         data &&
         user && */}
        <div className="allset-container3">
          <div className="allset-rightinfo">
            <div className="allset-profile">
              <div className="allset-name">
                <div className="allset-avatarnav">
                  <div className="allset-avatar">
                    <img
                      alt="IMAGE151I134"
                      src="/external/image151i134-ery-200h.png"
                      className="allset-image151"
                    />
                  </div>
                </div>
                <span className="allset-text02">
                  {/* <span>{data.username}</span> */}
                  {/* <span>{user.username}</span> */}
                </span>
                {/* <form action="https://api.gigglr.net/logout"> */}
                {/* <form action="http://localhost:8090/logout"> */}
                  {/* <button>Logout</button>
                </form> */}
                {/* <button onClick={handleLogout}>
                  Logout2
                </button> */}
              </div>
            </div>
          </div>
        </div>
  {/* }  */}
      </div>
      <div className="allset-container4">
        <div className="allset-steppermodal">
          <div className="allset-inputs">
            <div className="allset-description">
              <span className="allset-text04">
                <span>👍🏻</span>
              </span>
              <span className="allset-text06">
                <span>You&apos;re All Set!</span>
              </span>
              <span className="allset-text08">
                <span>
                  Your profile is now personalized and you&apos;re all set to
                  explore gigglr. Get ready to enjoy a world of comedy tailored
                  just for you!
                </span>
              </span>
            </div>
          </div>
          <div className="allset-ctasection">
            <button className="allset-button-primary">
              <span className="allset-text10">
              < Link to ="/home">Start Exploring</Link>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Allset
