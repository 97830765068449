// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N0G1z5SQePYdbn2aSs4z {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.MBH40pEO84DNgx9NBKZm {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: #3bb19b;
	border-radius: 20px;
	width: 180px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/views/EmailVerify/styles.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,aAAa;CACb,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,sBAAsB;AACvB;;AAEA;CACC,YAAY;CACZ,aAAa;CACb,eAAe;CACf,yBAAyB;CACzB,mBAAmB;CACnB,YAAY;CACZ,iBAAiB;CACjB,eAAe;CACf,eAAe;AAChB","sourcesContent":[".container {\n\twidth: 100vw;\n\theight: 100vh;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tflex-direction: column;\n}\n\n.green_btn {\n\tborder: none;\n\toutline: none;\n\tpadding: 12px 0;\n\tbackground-color: #3bb19b;\n\tborder-radius: 20px;\n\twidth: 180px;\n\tfont-weight: bold;\n\tfont-size: 14px;\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `N0G1z5SQePYdbn2aSs4z`,
	"green_btn": `MBH40pEO84DNgx9NBKZm`
};
export default ___CSS_LOADER_EXPORT___;
