import React from 'react'

import { Helmet } from 'react-helmet'
import { Link,Redirect } from 'react-router-dom'
import './mhome.css'

const Mhome = (props) => {

  // if (window.screen.width > 700) {
  //   return <Redirect to='/home'  />
  // }
  return (
    <div className="mhome-container">
      <Helmet>
        <title>Mhome - gigglr</title>
        <meta property="og:title" content="Mhome - gigglr" />
      </Helmet>
      <div className="mhome-home">
        <div className="mhome-top">
          <div className="mhome-top1">
            <div className="mhome-appbar">
              <div className="mhome-hometab">
                <div className="mhome-logohorizontal">
                  <img
                    alt="Gigglr0112I204"
                    src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/fa71f3ab-73bf-472f-9f08-a7d7ce126221?org_if_sml=1731&amp;force_format=original"
                    className="mhome-gigglr0112"
                  />
                  <span className="mhome-text">
                    <span>gigglr</span>
                  </span>
                </div>
              </div>
              <div className="mhome-bar">
                <div className="mhome-toptabitems">
                  <div className="mhome-uploadsquare01">
                    <div className="mhome-elements">
                      <img
                        alt="SubtractI204"
                        src="/external/subtracti204-xm5.svg"
                        className="mhome-subtract"
                      />
                    </div>
                  </div>
                </div>
                <div className="mhome-frame1000003399">
                  <div className="mhome-message">
                    <div className="mhome-group">
                      <img
                        alt="VectorI204"
                        src="/external/vectori204-oobg.svg"
                        className="mhome-vector"
                      />
                    </div>
                  </div>
                </div>
                <div className="mhome-frame1000003400">
                  <div className="mhome-notification">
                    <div className="mhome-group1">
                      <img
                        alt="VectorI204"
                        src="/external/vectori204-xcde.svg"
                        className="mhome-vector1"
                      />
                      <img
                        alt="Ellipse26I204"
                        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/a7198d24-a474-4f1a-a130-1b0ff5074429?org_if_sml=1190&amp;force_format=original"
                        className="mhome-ellipse26"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mhome-appbar1">
            <div className="mhome-input">
              <div className="mhome-left">
                <span className="mhome-text02">
                  <span className="mhome-text03">|</span>
                  <span>Enter your joke or funny thought here</span>
                </span>
              </div>
              <button className="mhome-actionbuttons">
                <button className="mhome-promptbutton">
                  <img
                    alt="pluscircleI204"
                    src="/external/pluscirclei204-o6x.svg"
                    className="mhome-pluscircle"
                  />
                </button>
              </button>
              <button className="mhome-button-primary">
                <span className="mhome-text05">
                  <span>Post</span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="mhome-frame589">
          <div className="mhome-notification1">
            <span className="mhome-text07">
              <span>Home</span>
            </span>
            <button className="mhome-mark-button">
              <span className="mhome-text09">
                <span>Newest and Recent</span>
              </span>
              <div className="mhome-arrowdown01sharp">
                <div className="mhome-elements1">
                  <img
                    alt="Vector6902I204"
                    src="/external/vector6902i204-ds0n.svg"
                    className="mhome-vector6902"
                  />
                </div>
              </div>
            </button>
          </div>
        </div>
        <div className="mhome-base">
          <div className="mhome-feed-post">
            <div className="mhome-user">
              <div className="mhome-avatar"></div>
              <div className="mhome-frame1572">
                <div className="mhome-frame1727">
                  <span className="mhome-text11">
                    <span>Robert Fox</span>
                  </span>
                </div>
                <div className="mhome-frame1678">
                  <span className="mhome-text13">•</span>
                  <span className="mhome-text14">
                    <span>August 25, 2023, 10:45 AM</span>
                  </span>
                </div>
              </div>
              <div className="mhome-systemsmore2fill">
                <div className="mhome-group2">
                  <img alt="VectorI204" src className="mhome-vector2" />
                  <img
                    alt="VectorI204"
                    src="/external/vectori204-oo2d9.svg"
                    className="mhome-vector3"
                  />
                </div>
              </div>
            </div>
            <div className="mhome-content">
              <span className="mhome-text16 Basebase1">
                <span>
                  I told my wife she was drawing her eyebrows too high. She
                  looked surprised.
                </span>
              </span>
              <div className="mhome-elements-photo">
                <img
                  alt="image111I204"
                  src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/34929c24-3d69-4138-b05e-c8f09e80f0ed?org_if_sml=1160743&amp;force_format=original"
                  className="mhome-image111"
                />
              </div>
            </div>
            <div className="mhome-frame7">
              <div className="mhome-counts">
                <div className="mhome-frame1042">
                  <img
                    alt="VectorI204"
                    src="/external/vectori204-21b.svg"
                    className="mhome-vector4"
                  />
                  <span className="mhome-text18">
                    <span>810</span>
                  </span>
                </div>
                <div className="mhome-frame1000003405">
                  <span className="mhome-text20">
                    <span>12 comment</span>
                  </span>
                  <span className="mhome-text22">
                    <span>456 Shared</span>
                  </span>
                </div>
              </div>
              <div className="mhome-actions">
                <img
                  alt="ButtonsI204"
                  src="/external/buttonsi204-skse.svg"
                  className="mhome-buttons"
                />
                <button className="mhome-buttons-button">
                  <div className="mhome-group14">
                    <img
                      alt="VectorI204"
                      src="/external/vectori204-2ryh.svg"
                      className="mhome-vector5"
                    />
                  </div>
                </button>
                <button className="mhome-buttons-button1">
                  <div className="mhome-group32">
                    <img
                      alt="VectorI204"
                      src="/external/vectori204-9jy.svg"
                      className="mhome-vector6"
                    />
                    <img
                      alt="VectorI204"
                      src="/external/vectori204-3b5.svg"
                      className="mhome-vector7"
                    />
                  </div>
                </button>
                <img
                  alt="ButtonsButtonI204"
                  src="/external/buttonsbuttoni204-6dih.svg"
                  className="mhome-buttons-button2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mhome-bottom-menu">
          <div className="mhome-menu">
            <div className="mhome-tab">
              <div className="mhome-toptabitems1">
                <div className="mhome-home05">
                  <div className="mhome-elements2">
                    <img
                      alt="SubtractI204"
                      src="/external/subtracti204-w0b.svg"
                      className="mhome-subtract1"
                    />
                    <img
                      alt="Rectangle2332StrokeI204"
                      src="/external/rectangle2332strokei204-ec5d.svg"
                      className="mhome-rectangle2332-stroke"
                    />
                  </div>
                </div>
              </div>
              <div className="mhome-toptabitems2">
                <div className="mhome-usersquare">
                  <div className="mhome-elements3">
                    <img
                      alt="SubtractI204"
                      src="/external/subtracti204-9bp7.svg"
                      className="mhome-subtract2"
                    />
                  </div>
                </div>
              </div>
              <div className="mhome-toptabitems3">
                <div className="mhome-bot">
                  <div className="mhome-content1">
                    <img
                      alt="SubtractI204"
                      src="/external/subtracti204-nzb.svg"
                      className="mhome-subtract3"
                    />
                    <img
                      alt="Vector7311StrokeI204"
                      src="/external/vector7311strokei204-ptn.svg"
                      className="mhome-vector7311-stroke"
                    />
                    <img
                      alt="Vector7312StrokeI204"
                      src="/external/vector7312strokei204-gcl8.svg"
                      className="mhome-vector7312-stroke"
                    />
                  </div>
                </div>
              </div>
              <div className="mhome-toptabitems4">
                <div className="mhome-hotprice">
                  <div className="mhome-elements4">
                    <img
                      alt="SubtractI204"
                      src="/external/subtracti204-3gs.svg"
                      className="mhome-subtract4"
                    />
                  </div>
                </div>
              </div>
              <div className="mhome-toptabitems5">
                <div className="mhome-avatar1">
                  <img
                    alt="IMAGE151I204"
                    src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/d2e31df4-ffd8-4675-bf38-0c33ab878c66?org_if_sml=11049&amp;force_format=original"
                    className="mhome-image151"
                  />
                </div>
              </div>
            </div>
          </div>
          <img
            alt="NativeHomeIndicatorI204"
            src="/external/nativehomeindicatori204-eir.svg"
            className="mhome-native-home-indicator"
          />
        </div>
      </div>
    </div>
  )
}

export default Mhome
