import React,{useState,useContext} from 'react'
import axios from'axios'
import { Helmet } from 'react-helmet'
import { Link,Redirect } from 'react-router-dom'
import './mregister.css'

const Mregister = (props) => {

  if (window.screen.width > 710) {
    return <Redirect to='/register'  />
  }

  const [data, setData] = useState({
    //	firstName: "",
    //	lastName: "",
      username:"",
      email: "",
      password: "",
    });
    const [error, setError] = useState("");
    const [msg, setMsg] = useState("");
  
    const handleChange = ({ currentTarget: input }) => {
      setData({ ...data, [input.name]: input.value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        // const url = "http://3.144.71.26:8090/api/users";
        const url = "https://api.gigglr.net/api/users";
        const { data: res } = await axios.post(url, data);
        setMsg(res.message);
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
      }
    };

  return (
    <div className="mregister-container">
      <Helmet>
        <title>Mregister - gigglr</title>
        <meta property="og:title" content="Mregister - gigglr" />
      </Helmet>
      <div className="mregister-newaccount">
        <div className="mregister-appbar">
          <Link to= "/">
          <img
            alt="arrowbackoutline2042"
            src="/external/arrowbackoutline2042-iphu.svg"
            className="mregister-arrowbackoutline"
          />
          </Link>
          <Link className ="loginbtn" to = "/mlogin">
               <span className ="loginbtntxt">Login</span>
          </Link>
        </div>
        <div className="mregister-frame587">
          <div className="mregister-gigglr0111">
            <img
              alt="Gigglr0111I204"
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/e3e15384-4963-4779-8968-81e104f9b004?org_if_sml=13407&amp;force_format=original"
              className="mregister-gigglr01111"
            />
          </div>
          <div className="mregister-frame1">
            <span className="mregister-text">
              <span>Welcome to gigglr</span>
            </span>
            <span className="mregister-text02">
              <span>Where Laughter Lives</span>
            </span>
          </div>
          <div className="mregister-openapp">
            <form className="mregister-button" action="https://api.gigglr.net/auth/google/callback">
              <img
                alt="Loginlogo2042"
                src="/external/loginlogo2042-mhjo.svg"
                className="mregister-loginlogo"
              />
              <button className="mregister-text04">
                <span>Continue with Google</span>
              </button>
            </form>
            <button className="mregister-button1">
              <div className="mregister-loginlogo1">
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="mregister-icon"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
              <span className="mregister-text06">
                <span>Continue with Apple</span>
              </span>
            </button>
          </div>
          <div className="mregister-frame590">
            <img
              alt="Rectangle13132042"
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/85f886cb-f945-49ec-9fb7-673e64b0b37c?org_if_sml=1142&amp;force_format=original"
              className="mregister-rectangle1313"
            />
            <span className="mregister-text08">
              <span>Or</span>
            </span>
            <img
              alt="Rectangle13142042"
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/b10680c1-0083-49dd-ac71-6c0ccc4219d5?org_if_sml=1142&amp;force_format=original"
              className="mregister-rectangle1314"
            />
          </div>
          <form  onSubmit={handleSubmit} className="mregister-frame588">
            <div className="mregister-input">
              <div className="mregister-frame562">
                <span className="mregister-text10">
                  <span>Choose username</span>
                </span>
                <span className="mregister-text12">
                  <span>Optional</span>
                </span>
              </div>
              <div className="mregister-frame469">
                {/* <span className="mregister-text14">
                  <span>e.g. GiggIruser</span>
                </span> */}
                 <input type='text' className="mregister-text14" placeholder='Enter Username'name='username' value={data.username} onChange={handleChange}/>
              </div>
            </div>
            <div className="mregister-input1">
              <div className="mregister-frame5621">
                <span className="mregister-text16">
                  <span>Email</span>
                </span>
                <span className="mregister-text18">
                  <span>Optional</span>
                </span>
              </div>
              <div className="mregister-frame4691">
                {/* <span className="mregister-text20">
                  <span>hi@email.com</span>
                </span> */}
                 <input type='text' className="mregister-text20"placeholder='Enter Email'name='email' value={data.email} onChange={handleChange}/> 
              </div>
            </div>
            <div className="mregister-input2">
              <div className="mregister-frame5622">
                <span className="mregister-text22">
                  <span>Password</span>
                </span>
                <span className="mregister-text24">
                  <span>Optional</span>
                </span>
              </div>
              <div className="mregister-frame4692">
                {/* <span className="mregister-text26">
                  <span>Password</span>
                </span> */}
                 <input type='text' className="mregister-text26" placeholder='Enter Password' name='password' value={data.password} onChange={handleChange}/>
              </div>
              <span className="mregister-text28">
                <span>At least 12 characters</span>
              </span>
            </div>
            {error && <div style={{color:'red'}} >{error}</div>}
						{msg && <div style={{color:'red'}} >{msg}</div>}
            <div className="mregister-buttons">
            <button type ="submit" className="mregister-button-primary">
              <span className="mregister-text30">
                <span>Join gigglr</span>
              </span>
            </button>
            <span className="mregister-text32">
              <span className="mregister-text33">
                By creating an account, you agree to our
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>Terms of Service</span>
              <span className="mregister-text35">
                {' '}
                and
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>

              <span>Privacy &amp; Cookie Statement.</span>
            </span>
            {/* <Link to = "/mlogin">LOGIN TO ACCOUNT</Link> */}
          </div>
          </form>
          {/* <button className="mregister-buttons">
            <button className="mregister-button-primary">
              <span className="mregister-text30">
                <span>Join gigglr</span>
              </span>
            </button>
            <span className="mregister-text32">
              <span className="mregister-text33">
                By creating an account, you agree to our
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>Terms of Service</span>
              <span className="mregister-text35">
                {' '}
                and
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>Privacy &amp; Cookie Statement.</span>
            </span>
          </button> */}
        </div>
        <img
          alt="NativeHomeIndicator2042"
          src="/external/nativehomeindicator2042-j1tg.svg"
          className="mregister-native-home-indicator"
        />
      </div>
    </div>
  )
}

export default Mregister
