import React,{useState,createContext} from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
 // Routes ,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Allset from './views/allset'
import Onboard1 from './views/onboard1'
import Onboard5 from './views/onboard5'
import Onboard4 from './views/onboard4'
import Splash from './views/splash'
import Login from './views/login'
import Home from './views/home'
import Onboard3 from './views/onboard3'
import Register from './views/register'
import Onboard2 from './views/onboard2'
import NotFound from './views/not-found'
import Forgotpass from './views/forgotpass'
import EmailVerify from './views/EmailVerify'
import PasswordReset from './views/PasswordReset'
import Settings from './views/settings'
//import Undercon from './views/undercon'
//import MSplash from './views/mobileui/m-splash'
import Mallset from './views/mobileui/mallset'
import Mforgotpass from './views/mobileui/mforgotpass'
import Mhome from './views/mobileui/mhome'
import Mlogin from './views/mobileui/mlogin'
import Monboard1 from './views/mobileui/monboard1'
import Monboard2 from './views/mobileui/monboard2'
import Monboard3 from './views/mobileui/monboard3'
import Monboard4 from './views/mobileui/monboard4'
import Monboard5 from './views/mobileui/monboard5'
import Mregister from './views/mobileui/mregister'
import Post from './views/post'
import Mprofile from './views/mobileui/mprofile'
import Msettings from './views/mobileui/msettings'
// export const store = createContext();
const App = () => {

  // const [token,setToken] = useState(null);
  const user = localStorage.getItem("token");

  return (
    // <store.Provider value={[token,setToken]}>


//     {/* // <Routes>
// 		// 	{user && <Route path="/" exact element={<Main />} />}
// 		// 	<Route path="/signup" exact element={<Signup />} />
// 		// 	<Route path="/login" exact element={<Login />} />
// 		// 	<Route path="/" element={<Navigate replace to="/login" />} />
// 		// 	<Route path="/users/:id/verify/:token" element={<EmailVerify />} />
// 		// 	<Route path="/forgot-password" element={<ForgotPassword />} />
// 		// 	<Route path="/password-reset/:id/:token" element={<PasswordReset />} />
// 		// </Routes>
//  */}



    <Router>
      <Switch>
      
      {/* {user && <Route component={Allset} exact path="/allset" />} */}
        <Route component={Allset} exact path="/allset" />
        <Route component={Mallset} exact path="/mallset" />
        <Route component={Mprofile} exact path="/mprofile" />
        <Route component={Msettings} exact path="/msettings" />
        <Route component={Mforgotpass} exact path="/mforgotpass" />
        <Route component={Mhome} exact path="/mhome" />
        <Route component={Mlogin} exact path="/mlogin" />
        <Route component={Post} exact path="/post" />
        <Route component={Monboard1} exact path="/monboard1" />
        <Route component={Monboard2} exact path="/monboard2" />
        <Route component={Monboard3} exact path="/monboard3" />
        <Route component={Monboard4} exact path="/monboard4" />
        <Route component={Monboard5} exact path="/monboard5" />
        <Route component={Mregister} exact path="/mregister" />
        <Route component={Onboard1} exact path="/onboard1" />
        <Route component={Onboard5} exact path="/onboard5" />
        <Route component={Onboard4} exact path="/onboard4" />
        <Route component={Settings} exact path="/settings" />
        
         {/* <Route  exact path="/"render={() => (
                  user ?
                      (<Redirect to="/sp"/>) :
                      (<Redirect to="/sp"/>)
              )}/>       */}
        <Route component={Splash} exact path="/" />
        <Route path="/users/:id/verify/:token" component={EmailVerify} />
        <Route path="/password-reset/:id/:token" component={PasswordReset } />
        <Route component={Login} exact path="/sign-in1" />
        <Route component={Home} exact path="/home" />
        <Route component={Onboard3} exact path="/onboard3" />
        <Route component={Register} exact path="/register" />
        <Route component={Onboard2} exact path="/onboard2" />
        <Route component={Forgotpass} exact path="/forgotpass" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      
      </Switch>
    </Router>

  // {/* {user &&  <Route component={Splash} exact path="/" />} */}
    //  </store.Provider>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
