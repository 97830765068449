import React,{useState,useContext} from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet'
import { Link,Redirect } from 'react-router-dom'
import './mlogin.css'

const Mlogin = (props) => {

  if (window.screen.width > 710) {
    return <Redirect to='/sign-in1'  />
  }

  const [data, setData] = useState({ email: "", password: "" });
	const [error, setError] = useState("");

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const url = "https://api.gigglr.net/api/auth";
			const { data: res } = await axios.post(url, data);
			localStorage.setItem("token", res.data);
			window.location = "/home";
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}
		}
	};


  return (
    <div className="mlogin-container">
      <Helmet>
        <title>Mlogin - gigglr</title>
        <meta property="og:title" content="Mlogin - gigglr" />
      </Helmet>
      <div className="mlogin-signin">
        <div className="mlogin-appbar">
          <Link to ='/mregister'>
          <img
            alt="arrowbackoutline2042"
            src="/external/arrowbackoutline2042-k0yf.svg"
            className="mlogin-arrowbackoutline"
          />
          </Link>
        </div>
        <div className="mlogin-frame587">
          <div className="mlogin-gigglr0111">
            <img
              alt="Gigglr0111I204"
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/7ca4288c-f6eb-45b5-80b6-42f27d386d97?org_if_sml=13393&amp;force_format=original"
              className="mlogin-gigglr01111"
            />
          </div>
          <span className="mlogin-text">
            <span>Sign in</span>
          </span>
          <div className="mlogin-openapp">
            <form className="mlogin-button" action="https://api.gigglr.net/auth/google/callback">
              <img
                alt="Loginlogo2042"
                src="/external/loginlogo2042-upr.svg"
                className="mlogin-loginlogo"
              />
              <button type ="submit" className="mlogin-text02">
                <span>Continue with Google</span>
              </button>
            </form>
            <button className="mlogin-button1">
              <div className="mlogin-loginlogo1">
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="mlogin-icon"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </div>
              <span className="mlogin-text04">Continue with Facebook</span>
            </button>
          </div>
          <div className="mlogin-frame590">
            <img
              alt="Rectangle13132042"
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/39f144c3-7816-4bc7-a954-6ecec0ef925b?org_if_sml=1151&amp;force_format=original"
              className="mlogin-rectangle1313"
            />
            <span className="mlogin-text05">
              <span>Or</span>
            </span>
            <img
              alt="Rectangle13142042"
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/f80b2abe-17f5-4cd0-bd61-73ce80801930?org_if_sml=1147&amp;force_format=original"
              className="mlogin-rectangle1314"
            />
          </div>
          <form onSubmit={handleSubmit}  className="mlogin-frame588">
            <div className="mlogin-input">
              <div className="mlogin-frame562">
                <span className="mlogin-text07">
                  <span>Email</span>
                </span>
                <span className="mlogin-text09">
                  <span>Optional</span>
                </span>
              </div>
              <div className="mlogin-frame469">
                {/* <span className="mlogin-text11">
                  <span>hi@email.com</span>
                </span> */}
                  <input type='text' className="mlogin-text11"placeholder='Enter Email'name='email' value={data.email} onChange={handleChange}/> 
              </div>
            </div>
            <div className="mlogin-input1">
              <div className="mlogin-frame5621">
                <span className="mlogin-text13">
                  <span>Password</span>
                </span>
                <span className="mlogin-text15">
                  <span>Optional</span>
                </span>
              </div>
              <div className="mlogin-frame4691">
                {/* <span className="mlogin-text17">
                  <span>Password</span>
                </span> */}
                 <input type='text' className="mlogin-text17" placeholder='Enter Password' name='password' value={data.password} onChange={handleChange}/> 
              </div>
            </div>
            {error && <div style={{color:'red'}}>{error}</div>}
            <div >
              <Link to ='/mforgotpass' className='forgotpass'>Forgot Password ?</Link>
            </div>
                <button type='submit' className="mlogin-buttons">
                <button className="mlogin-button-primary">
                  <span className="mlogin-text19">
                    <span>Sign in</span>
                  </span>
                </button>
              </button>
          </form>
          {/* <button className="mlogin-buttons">
            <button className="mlogin-button-primary">
              <span className="mlogin-text19">
                <span>Sign in</span>
              </span>
            </button>
          </button> */}
        </div>
        <img
          alt="NativeHomeIndicator2042"
          src="/external/nativehomeindicator2042-1yaj.svg"
          className="mlogin-native-home-indicator"
        />
      </div>
    </div>
  )
}

export default Mlogin
