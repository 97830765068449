// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J3BsD4XbrczyFGfSLSCp {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hKjtNFYO7eg_AiRt3Pzl {
	width: 400px;
	padding: 10px;
	background-color: white;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
		0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.kVqN6VkQQgQbKoegRuuC {
	outline: none;
	border: none;
	width: 370px;
	padding: 15px;
	border-radius: 10px;
	background-color: #edf5f3;
	margin: 5px 0;
	font-size: 14px;
}

.SxEv_8fbvoBaQ2Yq6KqS {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: #3bb19b;
	color: white;
	border-radius: 20px;
	width: 180px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
	margin: 10px auto;
}

.hy0V45MLOrViQJFraSAx,
.yFuvlYIrX3xs6Je0Z2kX {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
}

.yFuvlYIrX3xs6Je0Z2kX {
	background-color: #5cdd5c;
}
`, "",{"version":3,"sources":["webpack://./src/views/PasswordReset/styles.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,aAAa;CACb,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,YAAY;CACZ,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB;oEACmE;AACpE;;AAEA;CACC,aAAa;CACb,YAAY;CACZ,YAAY;CACZ,aAAa;CACb,mBAAmB;CACnB,yBAAyB;CACzB,aAAa;CACb,eAAe;AAChB;;AAEA;CACC,YAAY;CACZ,aAAa;CACb,eAAe;CACf,yBAAyB;CACzB,YAAY;CACZ,mBAAmB;CACnB,YAAY;CACZ,iBAAiB;CACjB,eAAe;CACf,eAAe;CACf,iBAAiB;AAClB;;AAEA;;CAEC,YAAY;CACZ,aAAa;CACb,aAAa;CACb,eAAe;CACf,yBAAyB;CACzB,YAAY;CACZ,kBAAkB;CAClB,kBAAkB;AACnB;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":[".container {\n\twidth: 100vw;\n\theight: 100vh;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.form_container {\n\twidth: 400px;\n\tpadding: 10px;\n\tbackground-color: white;\n\tborder-radius: 10px;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tbox-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),\n\t\t0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);\n}\n\n.input {\n\toutline: none;\n\tborder: none;\n\twidth: 370px;\n\tpadding: 15px;\n\tborder-radius: 10px;\n\tbackground-color: #edf5f3;\n\tmargin: 5px 0;\n\tfont-size: 14px;\n}\n\n.green_btn {\n\tborder: none;\n\toutline: none;\n\tpadding: 12px 0;\n\tbackground-color: #3bb19b;\n\tcolor: white;\n\tborder-radius: 20px;\n\twidth: 180px;\n\tfont-weight: bold;\n\tfont-size: 14px;\n\tcursor: pointer;\n\tmargin: 10px auto;\n}\n\n.error_msg,\n.success_msg {\n\twidth: 370px;\n\tpadding: 15px;\n\tmargin: 5px 0;\n\tfont-size: 14px;\n\tbackground-color: #f34646;\n\tcolor: white;\n\tborder-radius: 5px;\n\ttext-align: center;\n}\n\n.success_msg {\n\tbackground-color: #5cdd5c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `J3BsD4XbrczyFGfSLSCp`,
	"form_container": `hKjtNFYO7eg_AiRt3Pzl`,
	"input": `kVqN6VkQQgQbKoegRuuC`,
	"green_btn": `SxEv_8fbvoBaQ2Yq6KqS`,
	"error_msg": `hy0V45MLOrViQJFraSAx`,
	"success_msg": `yFuvlYIrX3xs6Je0Z2kX`
};
export default ___CSS_LOADER_EXPORT___;
