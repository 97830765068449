import React from 'react'

import { Helmet } from 'react-helmet'

import './mprofile.css'

const Mprofile = (props) => {
  return (
    <div className="mprofile-container">
      <Helmet>
        <title>Mprofile - gigglr</title>
        <meta property="og:title" content="Mprofile - gigglr" />
      </Helmet>
      <div className="mprofile-settings-edit-profile">
        <div className="mprofile-bar">
          <div className="mprofile-appbar">
            <img
              src="/external/arrowbackoutlinei218-bsn.svg"
              alt="arrowbackoutlineI218"
              className="mprofile-arrowbackoutline"
            />
            <span className="mprofile-text">
              <span>Profile</span>
            </span>
            <img
              src
              alt="arrowbackoutlineI218"
              className="mprofile-arrowbackoutline1"
            />
          </div>
        </div>
        <div className="mprofile-topitems">
          <div className="mprofile-settings">
            <div className="mprofile-avatar">
              <div className="mprofile-input">
                <span className="mprofile-text02">
                  <span>Avatar</span>
                </span>
                <div className="mprofile-frame471">
                  <div className="mprofile-avatar1">
                    <img
                      src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7e3cb39-a737-46fd-b9f1-8537895fbe89/e37753d4-4f3e-48c5-9e3d-b2b5031d89f6?org_if_sml=18011&amp;force_format=original"
                      alt="IMAGE151I218"
                      className="mprofile-image151"
                    />
                  </div>
                  <div className="mprofile-frame561">
                    <button className="mprofile-ghostbutton">
                      <span className="mprofile-text04">
                        <span>Upload new image</span>
                      </span>
                      <img
                        src="/external/uploadcloud01i218-sax8.svg"
                        alt="uploadcloud01I218"
                        className="mprofile-uploadcloud01"
                      />
                    </button>
                    <span className="mprofile-text06">
                      <span>
                        800x800 PNG, JPG is recommended. Maximum file size: 2Mb
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mprofile-input1">
              <div className="mprofile-frame562">
                <span className="mprofile-text08">
                  <span>Name</span>
                </span>
                <span className="mprofile-text10">
                  <span>Optional</span>
                </span>
              </div>
              <div className="mprofile-frame469">
                <img
                  src="/external/user01i218-snr.svg"
                  alt="user01I218"
                  className="mprofile-user01"
                />
                <span className="mprofile-text12">
                  <span>Tran Mau Tri Tam</span>
                </span>
              </div>
            </div>
            <div className="mprofile-input2">
              <div className="mprofile-frame5621">
                <span className="mprofile-text14">
                  <span>Location</span>
                </span>
                <span className="mprofile-text16">
                  <span>Optional</span>
                </span>
              </div>
              <div className="mprofile-frame4691">
                <img
                  src="/external/map02i218-qenn.svg"
                  alt="map02I218"
                  className="mprofile-map02"
                />
                <span className="mprofile-text18">
                  <span>Saigon, Vietnam</span>
                </span>
              </div>
            </div>
            <div className="mprofile-paragraphinputplaceholder">
              <span className="mprofile-text20">
                <span>Bio</span>
              </span>
              <div className="mprofile-frame4692">
                <span className="mprofile-text22">
                  <span>I.g. What is Gigglr UI kit</span>
                </span>
              </div>
              <span className="mprofile-text24">
                <span>Enter each message in a new line.</span>
              </span>
            </div>
          </div>
          <div className="mprofile-tab">
            <img
              src="/external/trash01i218-bka5.svg"
              alt="trash01I218"
              className="mprofile-trash01"
            />
            <span className="mprofile-text26">
              <span>Delete account</span>
            </span>
          </div>
        </div>
        <div className="mprofile-ctasection">
          <button className="mprofile-ghostbutton1">
            <span className="mprofile-text28">
              <span>Cancel</span>
            </span>
          </button>
          <button className="mprofile-button-primary">
            <span className="mprofile-text30">
              <span>Save change</span>
            </span>
            <img
              src="/external/checki218-xwvp.svg"
              alt="checkI218"
              className="mprofile-check"
            />
          </button>
        </div>
        <img
          src="/external/nativehomeindicator2181-pfw9.svg"
          alt="NativeHomeIndicator2181"
          className="mprofile-native-home-indicator"
        />
      </div>
    </div>
  )
}

export default Mprofile
