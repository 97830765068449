import React from 'react'

import { Helmet } from 'react-helmet'
import { Link,Redirect } from 'react-router-dom'
import './monboard1.css'

const Monboard1 = (props) => {

  if (window.screen.width > 710) {
    return <Redirect to='/onboard1'  />
  }
  return (
    <div className="monboard1-container">
      <Helmet>
        <title>Monboard1 - gigglr</title>
        <meta property="og:title" content="Monboard1 - gigglr" />
      </Helmet>
      <div className="monboard1-onboardingstep1">
        <div className="monboard1-bar">
          <div className="monboard1-appbar">
          <Link to= "/">
            <img
              alt="arrowbackoutlineI204"
              src="/external/arrowbackoutlinei204-n27a.svg"
              className="monboard1-arrowbackoutline"
            />
            </Link>
          </div>
        </div>
        <div className="monboard1-section">
          <div className="monboard1-frame427319187">
            <div className="monboard1-frame-basestepper">
              <div className="monboard1-frame-basestepper01">
                <span className="monboard1-text">1</span>
              </div>
            </div>
            <div className="monboard1-frame-dividerstepper">
              <img
                alt="DividerI204"
                src="/external/divideri204-lkg.svg"
                className="monboard1-divider"
              />
            </div>
            <div className="monboard1-frame-basestepper02">
              <div className="monboard1-frame-basestepper03">
                <span className="monboard1-text01">2</span>
              </div>
            </div>
            <div className="monboard1-frame-basestepper04">
              <div className="monboard1-frame-basestepper05">
                <span className="monboard1-text02">3</span>
              </div>
            </div>
            <div className="monboard1-frame-basestepper06">
              <div className="monboard1-frame-basestepper07">
                <span className="monboard1-text03">4</span>
              </div>
            </div>
            <div className="monboard1-frame-basestepper08">
              <div className="monboard1-frame-basestepper09">
                <span className="monboard1-text04">5</span>
              </div>
            </div>
            <div className="monboard1-frame-basestepper10">
              <div className="monboard1-frame-basestepper11">
                <span className="monboard1-text05">6</span>
              </div>
            </div>
          </div>
          <div className="monboard1-textandsupportingtext">
            <span className="monboard1-text06">
              <span>Welcome to gigglr!</span>
            </span>
            <span className="monboard1-text08">
              <span>Quick profile setup.</span>
            </span>
          </div>
        </div>
        <div className="monboard1-frame587">
          <div className="monboard1-inputs">
            <div className="monboard1-description">
              <span className="monboard1-text10">
                <span>Step 1</span>
              </span>
              <span className="monboard1-text12">
                <span>Welcome to gigglr</span>
              </span>
              <span className="monboard1-text14">
                <span>
                  Let&apos;s set up your profile for a personalized comedy
                  experience.
                </span>
              </span>
            </div>
            <div className="monboard1-videoplayer">
              <img
                alt="Shadowoverlayplayer2042"
                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/d14604cc-a40a-40bf-ac24-b6ada86df889?org_if_sml=119604&amp;force_format=original"
                className="monboard1-shadowoverlayplayer"
              />
              <img
                alt="Overlay2042"
                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/43fc7e43-3758-4e0c-b613-b0c792f42ccc?org_if_sml=11118&amp;force_format=original"
                className="monboard1-overlay"
              />
              <div className="monboard1-frame-videoprogress">
                <img
                  alt="BackgroundI204"
                  src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/2e026fe9-e426-48cd-bbb1-b04c4ee3d885?org_if_sml=1235&amp;force_format=original"
                  className="monboard1-background"
                />
                <div className="monboard1-buffering">
                  <img
                    alt="BufferingprogressI204"
                    src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/75177199-59ea-4932-a997-67e9377f0da9?org_if_sml=1222&amp;force_format=original"
                    className="monboard1-bufferingprogress"
                  />
                </div>
                <img
                  alt="ProgresslineI204"
                  src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c49e66b7-5601-4db8-8837-0c7c916c3b8b/21e1890c-cf0c-415e-ad31-ada4b9d70460?org_if_sml=1183&amp;force_format=original"
                  className="monboard1-progressline"
                />
                <img
                  alt="PlayI204"
                  src="/external/playi204-2qc4.svg"
                  className="monboard1-play"
                />
              </div>
              <div className="monboard1-textandbadge">
                <div className="monboard1-badge">
                  <span className="monboard1-text16">
                    <span>Guide</span>
                  </span>
                </div>
                <span className="monboard1-text18">
                  <span>How to get started</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="monboard1-ctasection">
          <button className="monboard1-button-primary">
            <span className="monboard1-text20">
            <Link to = "/monboard2">Next</Link>
            </span>
          </button>
        </div>
        <img
          alt="NativeHomeIndicator2042"
          src="/external/nativehomeindicator2042-vasb.svg"
          className="monboard1-native-home-indicator"
        />
      </div>
    </div>
  )
}

export default Monboard1
