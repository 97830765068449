import React,{useState} from 'react'
import axios from "axios";
import  './forgotpass.css'
import { Link,Redirect } from 'react-router-dom'

const Forgotpass = (props) => {

  if (window.screen.width < 700) {
    return <Redirect to='/mforgotpass'  />
  }


  const [email, setEmail] = useState("");
	const [msg, setMsg] = useState("");
	const [error, setError] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const url = `https://api.gigglr.net/api/password-reset`;
			const { data } = await axios.post(url, { email });
			setMsg(data.message);
			setError("");
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
				setMsg("");
			}
		}
	};



  return (
    <div className ='container'>
    <form className='forgot-password'  onSubmit={handleSubmit}>
      <Link to = '/sign-in1' className='frame586'>
        <button className='ghostbutton'>
          <span className='text'>
            <span>Sign in</span>
          </span>
          <img
            alt="login03I134"
            src="/external/login03i134-qyh7.svg"
            className='login03'
          />
        </button>
      </Link>
      <div className='frame587'>
        <div className='frame1'>
          <span className='text02'>
            <span>Forgot Password</span>
          </span>
          <span className='text04'>
            <span>
              Enter the username or email address associated with your gigglr
              account. We&apos;ll send you an email with instructions to reset
              your password.
            </span>
          </span>
        </div>
        <div className='frame588'>
          <div className='input'>
            <div className='frame562'>
              <span className='text06'>
                <span>Email</span>
              </span>
              <span className='text08'>
                <span>Optional</span>
              </span>
            </div>
            <div className='frame469'>
              {/* <input className='text10'>
               
              </input> */}
             <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                className='text10'
				     />
				
            </div>
          </div>
        </div>
              {error && <div style={{color:'red'}} >{error}</div>}
              {msg && <div style={{color:'red'}} >{msg}</div>}
        <button type="submit"  className='buttons'>
          <button className='button-primary'>
            <span className='text12'>
              <span>Send Reset Link</span>
            </span>
          </button>
        </button>
      </div>
    </form>
  </div>
)
}
export default Forgotpass
