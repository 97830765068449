import React from 'react'

import { Helmet } from 'react-helmet'

import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import './settings.css'

const Settings = (props) => {


  if (window.screen.width < 700) {
    return <Redirect to='/msettings'  />
  }
  return (
    <div className="settings-container">
      <Helmet>
        <title>gigglr</title>
        <meta property="og:title" content="gigglr" />
      </Helmet>
      <div className="settings-settings-edit-profile">
        <div className="settings-main">
          <div className="settings-createchatbot">
            <div className="settings-settingsidebar19201200">
              <div className="settings-topitems">
                <div className="settings-tab">
                  <img
                    src="/external/user01i134-bls.svg"
                    alt="user01I134"
                    className="settings-user01"
                  />
                  <span className="settings-text">
                    <span>Edit profile</span>
                  </span>
                </div>
                <div className="settings-tab01">
                  <img
                    src="/external/lock03i134-7gj.svg"
                    alt="lock03I134"
                    className="settings-lock03"
                  />
                  <span className="settings-text02">
                    <span>Password</span>
                  </span>
                </div>
                <div className="settings-tab02">
                  <img
                    src="/external/notificationtexti134-sbjc.svg"
                    alt="notificationtextI134"
                    className="settings-notificationtext"
                  />
                  <span className="settings-text04">
                    <span>Notifications</span>
                  </span>
                </div>
                <div className="settings-tab03">
                  <img
                    src="/external/notificationtexti134-773i.svg"
                    alt="notificationtextI134"
                    className="settings-notificationtext1"
                  />
                  <span className="settings-text06 Basebase2B">
                    <span>Nil Copyrights</span>
                  </span>
                </div>
                <div className="settings-tab04">
                  <img
                    src="/external/download04i134-e2at.svg"
                    alt="download04I134"
                    className="settings-download04"
                  />
                  <span className="settings-text08">
                    <span>Chat export</span>
                  </span>
                </div>
                <div className="settings-tab05">
                  <img
                    src="/external/login02i134-ep5p.svg"
                    alt="login02I134"
                    className="settings-login02"
                  />
                  <span className="settings-text10">
                    <span>Sessions</span>
                  </span>
                </div>
                <div className="settings-tab06">
                  <img
                    src="/external/containeri134-65f.svg"
                    alt="containerI134"
                    className="settings-container1"
                  />
                  <span className="settings-text12">
                    <span>Applications</span>
                  </span>
                </div>
                <div className="settings-tab07">
                  <img
                    src="/external/usersplusi134-yt3h.svg"
                    alt="usersplusI134"
                    className="settings-usersplus"
                  />
                  <span className="settings-text14">
                    <span>Team</span>
                  </span>
                </div>
              </div>
              <button className="settings-deletebutton">
                <div className="settings-tab08">
                  <img
                    src="/external/trash01i134-0ons.svg"
                    alt="trash01I134"
                    className="settings-trash01"
                  />
                  <span className="settings-text16">
                    <span>Delete account</span>
                  </span>
                </div>
              </button>
            </div>
            <div className="settings-settingscontainer">
              <span className="settings-text18">
                <span>Edit profile</span>
              </span>
              <div className="settings-settings">
                <div className="settings-avatar">
                  <div className="settings-input">
                    <span className="settings-text20">
                      <span>Avatar</span>
                    </span>
                    <div className="settings-frame471">
                      <div className="settings-avatar1">
                        <img
                          src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7e3cb39-a737-46fd-b9f1-8537895fbe89/94233ff2-8427-4a71-bf15-57871bd62f40?org_if_sml=116453&amp;force_format=original"
                          alt="IMAGE151I134"
                          className="settings-image151"
                        />
                      </div>
                      <div className="settings-frame561">
                        <button className="settings-ghostbutton">
                          <span className="settings-text22">
                            <span>Upload new image</span>
                          </span>
                          <img
                            src="/external/uploadcloud01i134-pvlc.svg"
                            alt="uploadcloud01I134"
                            className="settings-uploadcloud01"
                          />
                        </button>
                        <span className="settings-text24">
                          <span>
                            800x800 PNG, JPG is recommended. Maximum file size:
                            2Mb
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="settings-input1">
                  <div className="settings-frame562">
                    <span className="settings-text26">
                      <span>Name</span>
                    </span>
                    <span className="settings-text28">
                      <span>Optional</span>
                    </span>
                  </div>
                  <div className="settings-frame469">
                    <img
                      src="/external/user01i134-c5q1.svg"
                      alt="user01I134"
                      className="settings-user011"
                    />
                    <span className="settings-text30">
                      <span>Tran Mau Tri Tam</span>
                    </span>
                  </div>
                </div>
                <div className="settings-input2">
                  <div className="settings-frame5621">
                    <span className="settings-text32">
                      <span>Location</span>
                    </span>
                    <span className="settings-text34">
                      <span>Optional</span>
                    </span>
                  </div>
                  <div className="settings-frame4691">
                    <img
                      src="/external/map02i134-ii0d.svg"
                      alt="map02I134"
                      className="settings-map02"
                    />
                    <span className="settings-text36">
                      <span>Saigon, Vietnam</span>
                    </span>
                  </div>
                </div>
                <div className="settings-paragraphinputplaceholder">
                  <span className="settings-text38">
                    <span>Bio</span>
                  </span>
                  <div className="settings-frame4692">
                    <span className="settings-text40">
                      <span>I.g. What is gigglr UI kit</span>
                    </span>
                  </div>
                  <span className="settings-text42">
                    <span>Enter each message in a new line.</span>
                  </span>
                </div>
                <button className="settings-buttons">
                  <button className="settings-button-primary">
                    <span className="settings-text44">
                      <span>Save change</span>
                    </span>
                    <img
                      src="/external/checki134-a2us.svg"
                      alt="checkI134"
                      className="settings-check"
                    />
                  </button>
                  <button className="settings-ghostbutton1">
                    <span className="settings-text46">
                      <span>Cancel</span>
                    </span>
                  </button>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="settings-topbar">
          <div className="settings-left">
            <div className="settings-hometab">
              <div className="settings-logohorizontal">
                <img
                  src="/external/gigglr0112i134-tdrn-200h.png"
                  alt="Gigglr0112I134"
                  className="settings-gigglr0112"
                />
                <span className="settings-text48">
                  <span>gigglr</span>
                </span>
              </div>
            </div>
            <div className="settings-tab09">
              <div className="settings-toptabitems">
                <div className="settings-home05">
                  <div className="settings-elements">
                    <img
                      src="/external/subtracti134-l7yh.svg"
                      alt="SubtractI134"
                      className="settings-subtract"
                    />
                    <img
                      src="/external/rectangle2332strokei134-4cd.svg"
                      alt="Rectangle2332StrokeI134"
                      className="settings-rectangle2332-stroke"
                    />
                  </div>
                </div>
                <span className="settings-text50">
                  <span>Home</span>
                </span>
                <img src alt="xcloseI134" className="settings-xclose" />
              </div>
              <div className="settings-toptabitems1">
                <div className="settings-usersquare">
                  <div className="settings-elements1">
                    <img
                      src="/external/subtracti134-fuy8.svg"
                      alt="SubtractI134"
                      className="settings-subtract1"
                    />
                  </div>
                </div>
                <span className="settings-text52">
                  <span>Comedians</span>
                </span>
                <img src alt="xcloseI134" className="settings-xclose1" />
              </div>
              <div className="settings-toptabitems2">
                <div className="settings-bot">
                  <div className="settings-content">
                    <img
                      src="/external/subtracti134-z2mc.svg"
                      alt="SubtractI134"
                      className="settings-subtract2"
                    />
                    <img
                      src="/external/vector7311strokei134-usyr.svg"
                      alt="Vector7311StrokeI134"
                      className="settings-vector7311-stroke"
                    />
                    <img
                      src="/external/vector7312strokei134-csmu.svg"
                      alt="Vector7312StrokeI134"
                      className="settings-vector7312-stroke"
                    />
                  </div>
                </div>
                <span className="settings-text54">
                  <span>gigglr Chatbot</span>
                </span>
                <img src alt="xcloseI134" className="settings-xclose2" />
              </div>
              <div className="settings-toptabitems3">
                <div className="settings-hotprice">
                  <div className="settings-elements2">
                    <img
                      src="/external/subtracti134-nw8g.svg"
                      alt="SubtractI134"
                      className="settings-subtract3"
                    />
                  </div>
                </div>
                <span className="settings-text56">
                  <span>Plans</span>
                </span>
                <img src alt="xcloseI134" className="settings-xclose3" />
              </div>
            </div>
          </div>
          <div className="settings-rightinfo">
            <div className="settings-toptabitems4">
              <div className="settings-uploadsquare01">
                <div className="settings-elements3">
                  <img
                    src="/external/subtracti134-ylf.svg"
                    alt="SubtractI134"
                    className="settings-subtract4"
                  />
                </div>
              </div>
              <span className="settings-text58">
                <span>Upload</span>
              </span>
            </div>
            <div className="settings-frame1000003399">
              <div className="settings-message">
                <div className="settings-group">
                  <img
                    src="/external/vectori134-jfu9.svg"
                    alt="VectorI134"
                    className="settings-vector"
                  />
                </div>
              </div>
            </div>
            <div className="settings-frame1000003400">
              <div className="settings-notification">
                <div className="settings-group1">
                  <img
                    src="/external/vectori134-hw2j.svg"
                    alt="VectorI134"
                    className="settings-vector1"
                  />
                  <img
                    src="/external/ellipse26i134-b29-200h.png"
                    alt="Ellipse26I134"
                    className="settings-ellipse26"
                  />
                </div>
              </div>
            </div>
            <div className="settings-profile">
              <div className="settings-name">
                <div className="settings-avatarnav">
                  <div className="settings-avatar2">
                    <img
                      src="/external/image151i134-1cam-200h.png"
                      alt="IMAGE151I134"
                      className="settings-image1511"
                    />
                  </div>
                  <img
                    src="/external/ellipse10i134-ifik-200h.png"
                    alt="Ellipse10I134"
                    className="settings-ellipse10"
                  />
                </div>
                <span className="settings-text60">
                  <span>John Smith</span>
                </span>
              </div>
              <img
                src="/external/vector8i134-cowl.svg"
                alt="Vector8I134"
                className="settings-vector8"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
