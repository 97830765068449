import React from 'react'

import { Helmet } from 'react-helmet'
import { Link,Redirect } from 'react-router-dom'
import './monboard3.css'

const Monboard3 = (props) => {

  if (window.screen.width > 710) {
    return <Redirect to='/onboard3'  />
  }
  return (
    <div className="monboard3-container">
      <Helmet>
        <title>Monboard3 - gigglr</title>
        <meta property="og:title" content="Monboard3 - gigglr" />
      </Helmet>
      <div className="monboard3-onboardingstep3">
        <div className="monboard3-bar">
          <div className="monboard3-appbar">
          <Link to= "/monboard2">
            <img
              alt="arrowbackoutlineI204"
              src="/external/arrowbackoutlinei204-ls67.svg"
              className="monboard3-arrowbackoutline"
            />
            </Link>
          </div>
        </div>
        <div className="monboard3-section">
          <div className="monboard3-frame427319187">
            <div className="monboard3-frame-basestepper">
              <div className="monboard3-frame-basestepper01">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-ge6j.svg"
                  className="monboard3-essentialscheck"
                />
              </div>
            </div>
            <div className="monboard3-frame-basestepper02">
              <div className="monboard3-frame-basestepper03">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-88w3.svg"
                  className="monboard3-essentialscheck1"
                />
              </div>
            </div>
            <div className="monboard3-frame-basestepper04">
              <div className="monboard3-frame-basestepper05">
                <span className="monboard3-text">3</span>
              </div>
            </div>
            <div className="monboard3-frame-dividerstepper">
              <img
                alt="DividerI204"
                src="/external/divideri204-7n9.svg"
                className="monboard3-divider"
              />
            </div>
            <div className="monboard3-frame-basestepper06">
              <div className="monboard3-frame-basestepper07">
                <span className="monboard3-text01">4</span>
              </div>
            </div>
            <div className="monboard3-frame-basestepper08">
              <div className="monboard3-frame-basestepper09">
                <span className="monboard3-text02">5</span>
              </div>
            </div>
            <div className="monboard3-frame-basestepper10">
              <div className="monboard3-frame-basestepper11">
                <span className="monboard3-text03">6</span>
              </div>
            </div>
          </div>
          <div className="monboard3-textandsupportingtext">
            <span className="monboard3-text04">
              <span>Language</span>
            </span>
            <span className="monboard3-text06">
              <span>Pick your language.</span>
            </span>
          </div>
        </div>
        <div className="monboard3-frame587">
          <div className="monboard3-inputs">
            <div className="monboard3-description">
              <span className="monboard3-text08">
                <span>Step 3</span>
              </span>
              <span className="monboard3-text10">
                <span>Choose Your Language</span>
              </span>
              <span className="monboard3-text12">
                <span>
                  Select your preferred language for jokes and interactions.
                </span>
              </span>
            </div>
            <div className="monboard3-input">
              <div className="monboard3-frame562">
                <span className="monboard3-text14">
                  <span>Language</span>
                </span>
                <span className="monboard3-text16">
                  <span>Optional</span>
                </span>
              </div>
              <div className="monboard3-frame469">
                {/* <span className="monboard3-text18">
                  <span>Select</span>
                </span> */}
                 <select className="onboard3-text40">
                    <option className="language-text"> English</option>
                    <option className="language-text">French</option>
                    <option className="language-text">Spanish</option>
                  </select>
                <div className="monboard3-systemsarrowdownsline">
                  <div className="monboard3-group">
                    {/* <img alt="VectorI204" src className="monboard3-vector" /> */}
                    {/* <img
                      alt="VectorI204"
                      src="/external/vectori204-igz8.svg"
                      className="monboard3-vector1"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="monboard3-ctasection">
          <button className="monboard3-ghostbutton">
            <span className="monboard3-text20">
            <Link to = "/monboard2">Back</Link>
            </span>
          </button>
          <button className="monboard3-button-primary">
            <span className="monboard3-text22">
            <Link to = "/monboard4">Next</Link>
            </span>
          </button>
        </div>
        <img
          alt="NativeHomeIndicator2042"
          src="/external/nativehomeindicator2042-9u6r.svg"
          className="monboard3-native-home-indicator"
        />
      </div>
    </div>
  )
}

export default Monboard3
