import React,{useState,useContext} from 'react'
// import {store} from '../index';
import axios from 'axios';
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import { GoogleLogin } from 'react-google-login';
// refresh token
import { refreshTokenSetup } from './utils/refreshToken';
import { Helmet } from 'react-helmet'

import './login.css'

const clientId =
  '707788443358-u05p46nssla3l8tmn58tpo9r5sommgks.apps.googleusercontent.com';

const Login = (props) => {


  if (window.screen.width < 700) {
    return <Redirect to='/mlogin'  />
  }

  // const [token,setToken] = useContext(store)
  // const [data,setdata] = useState({
  //     email:'',
  //     password:'',
  // })
  // const changeHandler = e =>{
  //   setdata({...data,[e.target.name]:e.target.value})
  // }
  // const submitHandler = e =>{
  //     e.preventDefault();
  //     axios.post('https://api.gigglr.net/login',data).then(
  //         res => setToken(res.data.token)
  //     )
  // }
  // if(token){
  //    return <Redirect to='/home' />
  // }




  const [data, setData] = useState({ email: "", password: "" });
	const [error, setError] = useState("");

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			// const url = "http://localhost:8090/api/auth";
      const url = "https://api.gigglr.net/api/auth";
			const { data: res } = await axios.post(url, data);
			localStorage.setItem("token", res.data);
			window.location = "/home";
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}
		}
	};



////////
// const onSuccess = (res) => {
//   console.log('Login Success: currentUser:', res.profileObj);
//   alert(
//     `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
//   );
//   refreshTokenSetup(res);
// };

// const onFailure = (res) => {
//   // console.log('Login failed: res:', res);
//   // alert(
//   //   `Failed to login. 😢 Please ping this to admin`
//   // );
//   navigate.push("/sign-in1")
// };
///////



  return (
    <div className="login-container">
      <Helmet>
        <title>login </title>
        <meta property="og:title" content="login " />
      </Helmet>
      <div className="login-container1">
        <Link  to ="/register">
        <div className="login-ghostbutton">
          <span className="login-text">
          {/* < Link to ="/register">New account</Link> */}
          <span> New account</span>
          </span>
          <img
            alt="login03I134"
            src="/external/login03i134-qyh7.svg"
            className="login-login03"
          />
        </div>
        </Link>
      </div>
      <div className="login-container2">
        <div className="login-container3">
          <img
            alt="bg1349"
            src="/external/rectangle23347517-rfzg-600w.png"
            className="login-bg"
          />
        </div>
        <div className="login-container4">
          <div className="login-gigglr0111">
            <img
              alt="Gigglr0111I134"
              src="/external/gigglr0111i134-4ssf-200w-200w.png"
              className="login-gigglr01111"
            />
          </div>
          <span className="login-text02">
            <span>Sign in</span>
          </span>
          <div className="login-openapp">
            <form className="login-button" action="https://api.gigglr.net/auth/google/callback">
            {/* <form className="login-button" action="http://localhost:8090/auth/google/callback"> */}
              <img
                alt="Loginlogo1349"
                src="/external/loginlogo1349-2zio.svg"
                className="login-loginlogo"
              />
              <button className="login-text04">
                <span>Continue with Google</span>
              </button>
               {/* <GoogleLogin
                      clientId={clientId}
                      buttonText="Continue with Google"
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      cookiePolicy={'single_host_origin'}
                      style={{ marginTop: '100px' }}
                      isSignedIn={true}
                    /> */}
            </form>
            <form className="login-button1" action="http://3.144.71.26:8090/auth/facebook/callback">
              <svg viewBox="0 0 602.2582857142856 1024" className="login-icon">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
              <span className="login-text06">Continue with Facebook</span>
            </form>
          </div>
          <div className="login-frame590">
            <img
              alt="Rectangle13131349"
              src="/external/rectangle13131349-8zw-200h-200h.png"
              className="login-rectangle1313"
            />
            <span className="login-text07">
              <span>Or</span>
            </span>
            <img
              alt="Rectangle13141349"
              src="/external/rectangle13141349-jlzd-200h-200h.png"
              className="login-rectangle1314"
            />
          </div>
          {/* <form onSubmit={submitHandler} autoComplete='off' className="login-frame588"> */}
          <form onSubmit={handleSubmit}  className="login-frame588"> 
            <div className="login-input">
              <div className="login-frame562">
                <span className="login-text09">
                  <span>Email</span>
                </span>
                <span className="login-text11">
                  <span>Optional</span>
                </span>
              </div>
              <div className="login-frame469">
                {/* <span className="login-text13"> */}
                <input type='text' className="login-text13"placeholder='Enter Email'name='email' value={data.email} onChange={handleChange}/> 
                {/* </span> */}
              </div>
            </div>
            <div className="login-input1">
              <div className="login-frame5621">
                <span className="login-text15">
                  <span>Password</span>
                </span>
                <span className="login-text17">
                  <span>Optional</span>
                </span>
              </div>
              <div className="login-frame4691">
                {/* <span className="login-text19"> */}
                <input type='text' className="login-text19" placeholder='Enter Password' name='password' value={data.password} onChange={handleChange}/> 
                {/* </span> */}
              </div>
            </div>
              {error && <div style={{color:'red'}}>{error}</div>}
            <div >
              <Link to ='/forgotpass' className='forgotpass'>Forgot Password ?</Link>
            </div>
            <button type = "submit"className="login-buttons">
              <button className="login-button-primary">
                <span className="login-text21">
                  <span>Sign in</span>
                </span>
              </button>
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
