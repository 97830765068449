import React from 'react'

import { Helmet } from 'react-helmet'

import './msettings.css'

const Msettings = (props) => {
  return (
    <div className="msettings-container">
      <Helmet>
        <title>Msettings - gigglr</title>
        <meta property="og:title" content="Msettings - gigglr" />
      </Helmet>
      <div className="msettings-settings-edit-profile">
        <div className="msettings-bar">
          <div className="msettings-appbar">
            <img
              src="/external/arrowbackoutlinei218-q8x.svg"
              alt="arrowbackoutlineI218"
              className="msettings-arrowbackoutline"
            />
            <span className="msettings-text">
              <span>Profile</span>
            </span>
            <img
              src
              alt="arrowbackoutlineI218"
              className="msettings-arrowbackoutline1"
            />
          </div>
        </div>
        <div className="msettings-topitems">
          <div className="msettings-tab">
            <img
              src="/external/user01i218-v9d.svg"
              alt="user01I218"
              className="msettings-user01"
            />
            <span className="msettings-text02">
              <span>Edit profile</span>
            </span>
          </div>
          <div className="msettings-tab1">
            <img
              src="/external/lock03i218-zp4t.svg"
              alt="lock03I218"
              className="msettings-lock03"
            />
            <span className="msettings-text04">
              <span>Password</span>
            </span>
          </div>
          <div className="msettings-tab2">
            <img
              src="/external/notificationtexti218-vi7p.svg"
              alt="notificationtextI218"
              className="msettings-notificationtext"
            />
            <span className="msettings-text06">
              <span>Notifications</span>
            </span>
          </div>
          <div className="msettings-tab3">
            <img
              src="/external/notificationtexti266-9mns.svg"
              alt="notificationtextI266"
              className="msettings-notificationtext1"
            />
            <span className="msettings-text08">
              <span>NIL Copyrights</span>
            </span>
          </div>
          <div className="msettings-tab4">
            <img
              src="/external/download04i218-7ler.svg"
              alt="download04I218"
              className="msettings-download04"
            />
            <span className="msettings-text10">
              <span>Chat export</span>
            </span>
          </div>
          <div className="msettings-tab5">
            <img
              src="/external/login02i218-0fma.svg"
              alt="login02I218"
              className="msettings-login02"
            />
            <span className="msettings-text12">
              <span>Sessions</span>
            </span>
          </div>
          <div className="msettings-tab6">
            <img
              src="/external/containeri218-j84r.svg"
              alt="containerI218"
              className="msettings-container1"
            />
            <span className="msettings-text14">
              <span>Applications</span>
            </span>
          </div>
          <div className="msettings-tab7">
            <img
              src="/external/usersplusi218-k6e7.svg"
              alt="usersplusI218"
              className="msettings-usersplus"
            />
            <span className="msettings-text16">
              <span>Team</span>
            </span>
          </div>
        </div>
        <div className="msettings-bottom-menu">
          <div className="msettings-menu">
            <div className="msettings-tab8">
              <div className="msettings-toptabitems">
                <div className="msettings-home05">
                  <div className="msettings-elements">
                    <img
                      src="/external/subtracti218-dbfy.svg"
                      alt="SubtractI218"
                      className="msettings-subtract"
                    />
                    <img
                      src="/external/rectangle2332strokei218-wx9i.svg"
                      alt="Rectangle2332StrokeI218"
                      className="msettings-rectangle2332-stroke"
                    />
                  </div>
                </div>
              </div>
              <div className="msettings-toptabitems1">
                <div className="msettings-usersquare">
                  <div className="msettings-elements1">
                    <img
                      src="/external/subtracti218-8usq.svg"
                      alt="SubtractI218"
                      className="msettings-subtract1"
                    />
                  </div>
                </div>
              </div>
              <div className="msettings-toptabitems2">
                <div className="msettings-bot">
                  <div className="msettings-content">
                    <img
                      src="/external/subtracti218-3ti.svg"
                      alt="SubtractI218"
                      className="msettings-subtract2"
                    />
                    <img
                      src="/external/vector7311strokei218-q7b.svg"
                      alt="Vector7311StrokeI218"
                      className="msettings-vector7311-stroke"
                    />
                    <img
                      src="/external/vector7312strokei218-yfo2.svg"
                      alt="Vector7312StrokeI218"
                      className="msettings-vector7312-stroke"
                    />
                  </div>
                </div>
              </div>
              <div className="msettings-toptabitems3">
                <div className="msettings-hotprice">
                  <div className="msettings-elements2">
                    <img
                      src="/external/subtracti218-ttil.svg"
                      alt="SubtractI218"
                      className="msettings-subtract3"
                    />
                  </div>
                </div>
              </div>
              <div className="msettings-toptabitems4">
                <div className="msettings-avatar">
                  <img
                    src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7e3cb39-a737-46fd-b9f1-8537895fbe89/5c0856f3-79a4-460a-8a29-1abb955abf8c?org_if_sml=11003&amp;force_format=original"
                    alt="IMAGE151I218"
                    className="msettings-image151"
                  />
                </div>
              </div>
            </div>
          </div>
          <img
            src="/external/nativehomeindicatori218-dgoem.svg"
            alt="NativeHomeIndicatorI218"
            className="msettings-native-home-indicator"
          />
        </div>
      </div>
    </div>
  )
}

export default Msettings
