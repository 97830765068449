import React from 'react'
import { Link ,Redirect} from "react-router-dom";
import { Helmet } from 'react-helmet'

import './onboard5.css'
import Select from "react-select";
//import { useState } from "react";
//import { Postcat } from './postcats';



//const Onboard5 = (props) => {
  class Onboard5 extends React.Component {

    
  constructor(props) {
    super(props);

    this.state = { selectedOption: "" };
  }

  options = [
    { value: "Stand-Up", label: "Stand-Up" },
    { value: "Satire", label: "Satire" },
    { value: "Slapstick", label: "Slapstick" },
    { value: "Dark Humor", label: "Dark Humor" },
    { value: "Sarcasm", label: "Sarcasm" },
    { value: "Parody", label: "Parody" },
    { value: "Romantic", label: "Romantic" },
    { value: "Observational", label: "Observational" },
    { value: "Political", label: "Political" },
    { value: "Memes", label: "Memes" },
    { value: "Improve", label: "Improve" },
    { value: "Sketch", label: "Sketch" },
  ];
          
         


  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };
  // const [posttype, setPosttype] = useState([]);

  



render (){
  return (
    <div className="onboard5-container">
      <Helmet>
        <title>Onboard5 </title>
        <meta property="og:title" content="Onboard5 - Gigglr" />
      </Helmet>
      <div className="onboard5-container1">
        <div className="onboard5-container2">
          <div className="onboard5-section">
            <img
              alt="Gigglr01111349"
              src="/external/gigglr01111349-87wr-200w.png"
              className="onboard5-gigglr0111"
            />
            <div className="onboard5-frame427319187">
              <div className="onboard5-frame427319399">
                <div className="onboard5-frame4273191871">
                  <div className="onboard5-frame-basestepper">
                    <div className="onboard5-frame-basestepper01">
                      <img
                        alt="EssentialscheckI134"
                        src="/external/essentialschecki134-81p.svg"
                        className="onboard5-essentialscheck"
                      />
                    </div>
                  </div>
                  <div className="onboard5-frame-dividerstepper">
                    <img
                      alt="DividerI134"
                      src="/external/divideri134-4rpl.svg"
                      className="onboard5-divider"
                    />
                  </div>
                </div>
                <div className="onboard5-textandsupportingtext">
                  <span className="onboard5-text">
                    <span>Welcome to gigglr!</span>
                  </span>
                  <span className="onboard5-text02">
                    <span>Quick profile setup.</span>
                  </span>
                </div>
              </div>
              <div className="onboard5-frame427319400">
                <div className="onboard5-frame4273191872">
                  <div className="onboard5-frame-basestepper02">
                    <div className="onboard5-frame-basestepper03">
                      <img
                        alt="EssentialscheckI134"
                        src="/external/essentialschecki134-fgr8.svg"
                        className="onboard5-essentialscheck1"
                      />
                    </div>
                  </div>
                  <div className="onboard5-frame-dividerstepper1">
                    <img
                      alt="DividerI134"
                      src="/external/divideri134-nea.svg"
                      className="onboard5-divider1"
                    />
                  </div>
                </div>
                <div className="onboard5-textandsupportingtext1">
                  <span className="onboard5-text04">
                    <span>Your Location</span>
                  </span>
                  <span className="onboard5-text06">
                    <span>Tailor your content.</span>
                  </span>
                </div>
              </div>
              <div className="onboard5-frame427319401">
                <div className="onboard5-frame4273191873">
                  <div className="onboard5-frame-basestepper04">
                    <div className="onboard5-frame-basestepper05">
                      <img
                        alt="EssentialscheckI134"
                        src="/external/essentialschecki134-xx8b.svg"
                        className="onboard5-essentialscheck2"
                      />
                    </div>
                  </div>
                  <div className="onboard5-frame-dividerstepper2">
                    <img
                      alt="DividerI134"
                      src="/external/divideri134-l12b.svg"
                      className="onboard5-divider2"
                    />
                  </div>
                </div>
                <div className="onboard5-textandsupportingtext2">
                  <span className="onboard5-text08">
                    <span>Language</span>
                  </span>
                  <span className="onboard5-text10">
                    <span>Pick your language.</span>
                  </span>
                </div>
              </div>
              <div className="onboard5-frame427319402">
                <div className="onboard5-frame4273191874">
                  <div className="onboard5-frame-basestepper06">
                    <div className="onboard5-frame-basestepper07">
                      <img
                        alt="EssentialscheckI134"
                        src="/external/essentialschecki134-vgy.svg"
                        className="onboard5-essentialscheck3"
                      />
                    </div>
                  </div>
                  <div className="onboard5-frame-dividerstepper3">
                    <img
                      alt="DividerI134"
                      src="/external/divideri134-17jg.svg"
                      className="onboard5-divider3"
                    />
                  </div>
                </div>
                <div className="onboard5-textandsupportingtext3">
                  <span className="onboard5-text12">
                    <span>Comedy Ratings</span>
                  </span>
                  <span className="onboard5-text14">
                    <span>Choose joke ratings.</span>
                  </span>
                </div>
              </div>
              <div className="onboard5-frame427319403">
                <div className="onboard5-frame427319188">
                  <div className="onboard5-frame-basestepper08">
                    <div className="onboard5-frame-basestepper09">
                      <span className="onboard5-text16">5</span>
                    </div>
                  </div>
                  <div className="onboard5-frame-dividerstepper4">
                    <img
                      alt="DividerI134"
                      src="/external/divideri134-j0zv.svg"
                      className="onboard5-divider4"
                    />
                  </div>
                </div>
                <div className="onboard5-textandsupportingtext4">
                  <span className="onboard5-text17">
                    <span>Comedy Types</span>
                  </span>
                  <span className="onboard5-text19">
                    <span>Select comedy styles</span>
                  </span>
                </div>
              </div>
              <div className="onboard5-frame427319404">
                <div className="onboard5-frame4273191881">
                  <div className="onboard5-frame-basestepper10">
                    <div className="onboard5-frame-basestepper11">
                      <span className="onboard5-text21">6</span>
                    </div>
                  </div>
                </div>
                <div className="onboard5-textandsupportingtext5">
                  <span className="onboard5-text22">
                    <span>All Done!</span>
                  </span>
                  <span className="onboard5-text24">
                    <span>You&apos;re All Set Up!</span>
                  </span>
                </div>
              </div>
            </div>
            <span className="onboard5-text26">
              <span>Copyright gigglr 2023</span>
            </span>
          </div>
        </div>
        <div className="onboard5-container3">
          <div className="onboard5-container4">
            <div className="onboard5-inputs">
              <div className="onboard5-description">
                <span className="onboard5-text28">
                  <span>Step 5</span>
                </span>
                <span className="onboard5-text30">
                  <span>Choose Comedy Types</span>
                </span>
                <span className="onboard5-text32">
                  <span>
                    What kind of comedy do you enjoy? Pick 5 kind you like!
                  </span>
                </span>
              </div>
              <div className='selectdiv'>
                 <Select
                 isMulti
                 value={this.state.selectedOption}
                // onChange={(e) => setPosttype(e)}
                 onChange={this.handleChange}
                 name="title"
                // options={Postcat}
                options={this.options}
                // options={posttype}
                 // only allow user to choose up to 3 options
                // isOptionDisabled={() => posttype.length >= 5}
                 isOptionDisabled={() => this.state.selectedOption.length >= 5}
                 className="basic-multi-select"
                 classNamePrefix="select"
                 />
              </div>
              
              {/* <div className="onboard5-frame1">
                <div className="onboard5-frame11">
                  <button className="onboard5-ghostbutton">
                    <span className="onboard5-text34">
                      <span>Stand-Up</span>
                    </span>
                  </button>
                  <button className="onboard5-button-primary">
                    <span className="onboard5-text36">
                      <span>Satire</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton01">
                    <span className="onboard5-text38">
                      <span>Slapstick</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton02">
                    <span className="onboard5-text40">
                      <span>Dark Humor</span>
                    </span>
                  </button>
                </div>
                <div className="onboard5-frame2">
                  <button className="onboard5-ghostbutton03">
                    <span className="onboard5-text42">
                      <span>Sarcasm</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton04">
                    <span className="onboard5-text44">
                      <span>Parody</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton05">
                    <span className="onboard5-text46">
                      <span>Romantic</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton06">
                    <span className="onboard5-text48">
                      <span>Observational</span>
                    </span>
                  </button>
                </div>
                <div className="onboard5-frame3">
                  <button className="onboard5-ghostbutton07">
                    <span className="onboard5-text50">
                      <span>Political</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton08">
                    <span className="onboard5-text52">
                      <span>Memes</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton09">
                    <span className="onboard5-text54">
                      <span>Improve</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton10">
                    <span className="onboard5-text56">
                      <span>Sketch</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton11">
                    <span className="onboard5-text58">
                      <span>Physical</span>
                    </span>
                  </button>
                </div>
                <div className="onboard5-frame4">
                  <button className="onboard5-ghostbutton12">
                    <span className="onboard5-text60">
                      <span>Absurdist</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton13">
                    <span className="onboard5-text62">
                      <span>Musical</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton14">
                    <span className="onboard5-text64">
                      <span>Historical</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton15">
                    <span className="onboard5-text66">
                      <span>Cringe Comedy</span>
                    </span>
                  </button>
                </div>
                <div className="onboard5-frame5">
                  <button className="onboard5-ghostbutton16">
                    <span className="onboard5-text68">
                      <span>Science Humor</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton17">
                    <span className="onboard5-text70">
                      <span>Word Play</span>
                    </span>
                  </button>
                  <button className="onboard5-ghostbutton18">
                    <span className="onboard5-text72">
                      <span>Cult Classic</span>
                    </span>
                  </button>
                </div>
              </div> */}
              
            </div>
          </div>
          <div className="onboard5-container5">
            <div className="onboard5-ctasection">
              <button className="onboard5-ghostbutton19">
                <span className="onboard5-text74">
                <Link to = "/onboard4">Back</Link>
                </span>
              </button>
              <button className="onboard5-button-primary1">
                <span className="onboard5-text76">
                <Link to = "/allset">Next</Link>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
  }
export default Onboard5
