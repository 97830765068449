import React from 'react'

import { Helmet } from 'react-helmet'

import './post.css'

const Post = (props) => {
  return (
    <div className="post-container">
      <Helmet>
        <title>post - gigglr</title>
        <meta property="og:title" content="post - gigglr" />
      </Helmet>
      <div className="post-new-post">
        <div className="post-main">
          <div className="post-sidebar">
            <div className="post-connect-cards">
              <span className="post-text">
                <span>Your Feed</span>
              </span>
              <div className="post-frame1000003402">
                <div className="post-frame2313">
                  <div className="post-frame1000003399">
                    <div className="post-icfibernew24px2">
                      <div className="post-group6">
                        <img
                          alt="Star1I134"
                          src="/external/star1i134-sh6j.svg"
                          className="post-star1"
                        />
                        <span className="post-text002">
                          <span>New</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="post-frame958">
                    <span className="post-text004">
                      <span>Newest and Recent</span>
                    </span>
                    <span className="post-text006">
                      <span>Find the latest update</span>
                    </span>
                  </div>
                </div>
                <div className="post-frame2325">
                  <div className="post-frame100000339901">
                    <div className="post-frame15">
                      <div className="post-group">
                        <img
                          alt="VectorI134"
                          src="/external/vectori134-ukcce.svg"
                          className="post-vector"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="post-frame95801">
                    <span className="post-text008">
                      <span>Popular of the day</span>
                    </span>
                    <span className="post-text010">
                      <span>Shots featured today by curators</span>
                    </span>
                  </div>
                </div>
                <div className="post-frame2326">
                  <div className="post-frame100000339902">
                    <img
                      alt="Follow1341"
                      src="/external/follow1341-3qi.svg"
                      className="post-follow"
                    />
                  </div>
                  <div className="post-frame95802">
                    <span className="post-text012">
                      <span>Following</span>
                    </span>
                    <span className="post-text014">
                      <span>From your favorite Comendian</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="post-connect-cards1">
              <span className="post-text016">
                <span>Your Profile</span>
              </span>
              <div className="post-avatarnav">
                <div className="post-avatar">
                  <img
                    alt="IMAGE151I134"
                    src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7e3cb39-a737-46fd-b9f1-8537895fbe89/edfe91bf-1ffb-4c79-b3de-069fb7e68a2f?org_if_sml=16344&amp;force_format=original"
                    className="post-image151"
                  />
                </div>
              </div>
              <div className="post-frame673">
                <span className="post-text018">
                  <span>John Doe</span>
                </span>
                <span className="post-text020">
                  <span>@jane_smith</span>
                </span>
              </div>
              <div className="post-frame2316">
                <div className="post-frame23131">
                  <span className="post-text022">
                    <span>3,212</span>
                  </span>
                  <span className="post-text024">
                    <span>Followers</span>
                  </span>
                </div>
                <div className="post-frame23161">
                  <span className="post-text026">
                    <span>12</span>
                  </span>
                  <span className="post-text028">
                    <span>Following</span>
                  </span>
                </div>
                <div className="post-frame2317">
                  <span className="post-text030">
                    <span>213</span>
                  </span>
                  <span className="post-text032">
                    <span>Jokes</span>
                  </span>
                </div>
              </div>
              <div className="post-frame10000034021">
                <div className="post-frame23132">
                  <div className="post-frame959">
                    <div className="post-frame1000003401">
                      <div className="post-frame100000339903">
                        <div className="post-message">
                          <div className="post-group01">
                            <img
                              alt="VectorI134"
                              src="/external/vectori134-kfeh.svg"
                              className="post-vector01"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="post-frame95803">
                        <span className="post-text034">
                          <span>Messages</span>
                        </span>
                        <span className="post-text036">
                          <span>39 Unread messages</span>
                        </span>
                      </div>
                    </div>
                    <div className="post-systemsarrowdroprightline">
                      <div className="post-group02">
                        <img alt="VectorI134" src className="post-vector02" />
                        <img
                          alt="VectorI134"
                          src="/external/vectori134-3hvg.svg"
                          className="post-vector03"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="post-frame23251">
                  <div className="post-frame9591">
                    <div className="post-frame10000034011">
                      <div className="post-frame100000339904">
                        <div className="post-notification">
                          <div className="post-group03">
                            <img
                              alt="VectorI134"
                              src="/external/vectori134-uoqh.svg"
                              className="post-vector04"
                            />
                            <img
                              alt="Ellipse26I134"
                              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7e3cb39-a737-46fd-b9f1-8537895fbe89/d7aa3b01-bdee-49f2-beeb-ad4d5268fabd?org_if_sml=1183&amp;force_format=original"
                              className="post-ellipse26"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="post-frame95804">
                        <span className="post-text038">
                          <span>Notifications</span>
                        </span>
                        <span className="post-text040">
                          <span>39 Unread notifications</span>
                        </span>
                      </div>
                    </div>
                    <div className="post-systemsarrowdroprightline1">
                      <div className="post-group04">
                        <img alt="VectorI134" src className="post-vector05" />
                        <img
                          alt="VectorI134"
                          src="/external/vectori134-1va.svg"
                          className="post-vector06"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="post-main1">
            <div className="post-promptinputdefault">
              <div className="post-input">
                <div className="post-left">
                  <span className="post-text042">
                    <span className="post-text043">|</span>
                    <span>Enter your joke or funny thought here</span>
                  </span>
                </div>
                <button className="post-actionbuttons">
                  <button className="post-promptbutton">
                    <img
                      alt="pluscircleI134"
                      src="/external/pluscirclei134-39y.svg"
                      className="post-pluscircle"
                    />
                  </button>
                </button>
                <button className="post-button-primary">
                  <span className="post-text045">
                    <span>Create Post</span>
                  </span>
                </button>
              </div>
            </div>
            <div className="post-feed-post">
              <div className="post-user">
                <div className="post-avatar01"></div>
                <div className="post-frame1572">
                  <div className="post-frame1727">
                    <span className="post-text047">
                      <span>Robert Fox</span>
                    </span>
                  </div>
                  <div className="post-frame1678">
                    <span className="post-text049">•</span>
                    <span className="post-text050">
                      <span>August 25, 2023, 10:45 AM</span>
                    </span>
                  </div>
                </div>
                <div className="post-systemsmore2fill">
                  <div className="post-group05">
                    <img alt="VectorI134" src className="post-vector07" />
                    <img
                      alt="VectorI134"
                      src="/external/vectori134-swa.svg"
                      className="post-vector08"
                    />
                  </div>
                </div>
              </div>
              <div className="post-content">
                <span className="post-text052">
                  <span>
                    I told my wife she was drawing her eyebrows too high. She
                    looked surprised.
                  </span>
                </span>
                <div className="post-elements-photo">
                  <img
                    alt="image111I134"
                    src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7e3cb39-a737-46fd-b9f1-8537895fbe89/0364aa21-d49c-4ebf-af66-c52aaf3b6f78?org_if_sml=1288284&amp;force_format=original"
                    className="post-image111"
                  />
                </div>
              </div>
              <div className="post-frame7">
                <div className="post-counts">
                  <div className="post-frame1042">
                    <img
                      alt="VectorI134"
                      src="/external/vectori134-uiol.svg"
                      className="post-vector09"
                    />
                    <span className="post-text054">
                      <span>810</span>
                    </span>
                  </div>
                  <div className="post-frame1000003405">
                    <span className="post-text056">
                      <span>12 comment</span>
                    </span>
                    <span className="post-text058">
                      <span>456 Shared</span>
                    </span>
                  </div>
                </div>
                <div className="post-actions">
                  <button className="post-buttons">
                    <img
                      alt="VectorI134"
                      src="/external/vectori134-2f45.svg"
                      className="post-vector10"
                    />
                    <span className="post-text060">
                      <span>Like</span>
                    </span>
                  </button>
                  <button className="post-buttons-button">
                    <div>
                      <img
                        alt="VectorI134"
                        src="/external/vectori134-cwum.svg"
                        className="post-vector11"
                      />
                    </div>
                    <span className="post-text062">
                      <span>Comment</span>
                    </span>
                  </button>
                  <button className="post-buttons-button1">
                    <div className="post-group32">
                      <img
                        alt="VectorI134"
                        src="/external/vectori134-ysv8.svg"
                        className="post-vector12"
                      />
                      <img
                        alt="VectorI134"
                        src="/external/vectori134-iynl.svg"
                        className="post-vector13"
                      />
                    </div>
                    <span className="post-text064">
                      <span>Share</span>
                    </span>
                  </button>
                  <button className="post-buttons-button2">
                    <img
                      alt="PathI134"
                      src="/external/pathi134-tmvj.svg"
                      className="post-path"
                    />
                    <span className="post-text066">
                      <span>Save</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="post-feed-post1">
              <div className="post-user1">
                <div className="post-avatar02"></div>
                <div className="post-frame15721">
                  <div className="post-frame17271">
                    <span className="post-text068">
                      <span>Cody Fisher</span>
                    </span>
                  </div>
                  <div className="post-frame16781">
                    <span className="post-text070">•</span>
                    <span className="post-text071">
                      <span>August 25, 2023, 10:45 AM</span>
                    </span>
                  </div>
                </div>
                <div className="post-systemsmore2fill1">
                  <div className="post-group06">
                    <img alt="VectorI134" src className="post-vector14" />
                    <img
                      alt="VectorI134"
                      src="/external/vectori134-cfqh.svg"
                      className="post-vector15"
                    />
                  </div>
                </div>
              </div>
              <div className="post-content1">
                <span className="post-text073">
                  <span>
                    Went to a protest against procrastination. It starts next
                    week.
                  </span>
                </span>
                <div className="post-elements-photo1">
                  <img
                    alt="image111I134"
                    src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7e3cb39-a737-46fd-b9f1-8537895fbe89/07334b62-403f-4606-9ec7-8c7cb0d289aa?org_if_sml=160204&amp;force_format=original"
                    className="post-image1111"
                  />
                  <div className="post-frame147">
                    <img alt="playI134" src className="post-play" />
                  </div>
                </div>
              </div>
              <div className="post-frame71">
                <div className="post-counts1">
                  <div className="post-frame10421">
                    <img alt="VectorI134" src className="post-vector16" />
                    <span className="post-text075">
                      <span>810</span>
                    </span>
                  </div>
                  <div className="post-frame10000034051">
                    <span className="post-text077">
                      <span>12 comment</span>
                    </span>
                    <span className="post-text079">
                      <span>456 Shared</span>
                    </span>
                  </div>
                </div>
                <div className="post-actions1">
                  <button className="post-buttons1">
                    <img alt="VectorI134" src className="post-vector17" />
                    <span className="post-text081">
                      <span>Liked</span>
                    </span>
                  </button>
                  <button className="post-buttons-button3">
                    <div className="post-group141">
                      <img alt="VectorI134" src className="post-vector18" />
                    </div>
                    <span className="post-text083">
                      <span>Comment</span>
                    </span>
                  </button>
                  <button className="post-buttons-button4">
                    <div className="post-group321">
                      <img alt="VectorI134" src className="post-vector19" />
                      <img alt="VectorI134" src className="post-vector20" />
                    </div>
                    <span className="post-text085">
                      <span>Share</span>
                    </span>
                  </button>
                  <button className="post-buttons-button5">
                    <img alt="PathI134" src className="post-path1" />
                    <span className="post-text087">
                      <span>Save</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="post-feed-post2">
              <div className="post-user2">
                <div className="post-avatar03"></div>
                <div className="post-frame15722">
                  <div className="post-frame17272">
                    <span className="post-text089">
                      <span>Albert Flores</span>
                    </span>
                  </div>
                  <div className="post-frame16782">
                    <span className="post-text091">•</span>
                    <span className="post-text092">
                      <span>August 25, 2023, 10:45 AM</span>
                    </span>
                  </div>
                </div>
                <div className="post-systemsmore2fill2">
                  <div className="post-group07">
                    <img alt="VectorI134" src className="post-vector21" />
                    <img alt="VectorI134" src className="post-vector22" />
                  </div>
                </div>
              </div>
              <div className="post-content2">
                <span className="post-text094">
                  <span>
                    I told my wife she was drawing her eyebrows too high. She
                    looked surprised.
                  </span>
                </span>
                <div className="post-elements-photo2">
                  <img
                    alt="image111I134"
                    src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7e3cb39-a737-46fd-b9f1-8537895fbe89/48038e25-7b0c-4058-8ed9-1a17c6fa8c70?org_if_sml=1123&amp;force_format=original"
                    className="post-image1112"
                  />
                </div>
              </div>
              <div className="post-frame72">
                <div className="post-counts2">
                  <div className="post-frame10422">
                    <img alt="VectorI134" src className="post-vector23" />
                    <span className="post-text096">
                      <span>810</span>
                    </span>
                  </div>
                  <div className="post-frame10000034052">
                    <span className="post-text098">
                      <span>12 comment</span>
                    </span>
                    <span className="post-text100">
                      <span>456 Shared</span>
                    </span>
                  </div>
                </div>
                <div className="post-actions2">
                  <button className="post-buttons2">
                    <img alt="VectorI134" src className="post-vector24" />
                    <span className="post-text102">
                      <span>Like</span>
                    </span>
                  </button>
                  <button className="post-buttons-button6">
                    <div className="post-group142">
                      <img alt="VectorI134" src className="post-vector25" />
                    </div>
                    <span className="post-text104">
                      <span>Comment</span>
                    </span>
                  </button>
                  <button className="post-buttons-button7">
                    <div className="post-group322">
                      <img alt="VectorI134" src className="post-vector26" />
                      <img alt="VectorI134" src className="post-vector27" />
                    </div>
                    <span className="post-text106">
                      <span>Share</span>
                    </span>
                  </button>
                  <button className="post-buttons-button8">
                    <img alt="PathI134" src className="post-path2" />
                    <span className="post-text108">
                      <span>Save</span>
                    </span>
                  </button>
                </div>
              </div>
              <div className="post-input1">
                <div className="post-left1">
                  <div className="post-avatar04">
                    <img
                      alt="IMAGE151I134"
                      src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7e3cb39-a737-46fd-b9f1-8537895fbe89/0a559fa2-3c01-4895-920c-e0edd85800a6?org_if_sml=1123&amp;force_format=original"
                      className="post-image1511"
                    />
                  </div>
                  <span className="post-text110">
                    <span className="post-text111">|</span>
                    <span>Write your comment</span>
                  </span>
                </div>
                <button className="post-actionbuttons1">
                  <button className="post-promptbutton1">
                    <div className="post-neutral">
                      <div className="post-elements">
                        <img
                          alt="Ellipse1770I134"
                          src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7e3cb39-a737-46fd-b9f1-8537895fbe89/2d53725f-4e44-4652-b71c-59a687497d00?org_if_sml=1123&amp;force_format=original"
                          className="post-ellipse1770"
                        />
                        <img alt="VectorI134" src className="post-vector28" />
                        <img
                          alt="Ellipse1771I134"
                          src
                          className="post-ellipse1771"
                        />
                      </div>
                    </div>
                  </button>
                </button>
              </div>
            </div>
          </div>
          <div className="post-sidebar1">
            <div className="post-connect-cards2">
              <span className="post-text113">
                <span>Trending Prompts</span>
              </span>
              <div className="post-frame10000034022">
                <div className="post-frame23133">
                  <div className="post-frame100000339905">
                    <span className="post-text115">1</span>
                  </div>
                  <div className="post-frame95805">
                    <span className="post-text116">
                      <span>#yomama</span>
                    </span>
                    <span className="post-text118">
                      <span>4,600 jokes</span>
                    </span>
                  </div>
                </div>
                <div className="post-frame2314">
                  <div className="post-frame100000339906">
                    <span className="post-text120">2</span>
                  </div>
                  <div className="post-frame95806">
                    <span className="post-text121">
                      <span>#knockknock</span>
                    </span>
                    <span className="post-text123">
                      <span>4,600 jokes</span>
                    </span>
                  </div>
                </div>
                <div className="post-frame2315">
                  <div className="post-frame100000339907">
                    <span className="post-text125">3</span>
                  </div>
                  <div className="post-frame95807">
                    <span className="post-text126">
                      <span>#blondes</span>
                    </span>
                    <span className="post-text128">
                      <span>4,600 jokes</span>
                    </span>
                  </div>
                </div>
                <div className="post-frame23162">
                  <div className="post-frame100000339908">
                    <span className="post-text130">4</span>
                  </div>
                  <div className="post-frame95808">
                    <span className="post-text131">
                      <span>#puns</span>
                    </span>
                    <span className="post-text133">
                      <span>4,600 jokes</span>
                    </span>
                  </div>
                </div>
                <div className="post-frame23171">
                  <div className="post-frame100000339909">
                    <span className="post-text135">5</span>
                  </div>
                  <div className="post-frame95809">
                    <span className="post-text136">
                      <span>#dadjokes</span>
                    </span>
                    <span className="post-text138">
                      <span>4,600 jokes</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="post-connect-cards3">
              <span className="post-text140">
                <span>Recommended Comedians</span>
              </span>
              <div className="post-frame10000034023">
                <div className="post-frame23134">
                  <div className="post-avatar05"></div>
                  <div className="post-frame95810">
                    <span className="post-text142">
                      <span>Marvin McKinney</span>
                    </span>
                    <div className="post-frame16783">
                      <span className="post-text144">
                        <span>Dad Jokes</span>
                      </span>
                      <span className="post-text146">•</span>
                      <span className="post-text147">
                        <span>4.5k Followers</span>
                      </span>
                    </div>
                  </div>
                  <button className="post-button-primary1">
                    <div className="post-plussign">
                      <div className="post-elements1">
                        <img
                          alt="VectorStrokeI134"
                          src="/external/vectorstrokei134-moh.svg"
                          className="post-vector-stroke"
                        />
                      </div>
                    </div>
                  </button>
                </div>
                <div className="post-frame2327">
                  <div className="post-avatar06"></div>
                  <div className="post-frame95811">
                    <span className="post-text149">
                      <span>Robert Fox</span>
                    </span>
                    <div className="post-frame16784">
                      <span className="post-text151">
                        <span>Dad Jokes</span>
                      </span>
                      <span className="post-text153">•</span>
                      <span className="post-text154">
                        <span>4.5k Followers</span>
                      </span>
                    </div>
                  </div>
                  <button className="post-button-primary2">
                    <div className="post-plussign1">
                      <div className="post-elements2">
                        <img
                          alt="VectorStrokeI134"
                          src="/external/vectorstrokei134-dhe2.svg"
                          className="post-vector-stroke1"
                        />
                      </div>
                    </div>
                  </button>
                </div>
                <div className="post-frame2328">
                  <div className="post-avatar07"></div>
                  <div className="post-frame95812">
                    <span className="post-text156">
                      <span>Albert Flores</span>
                    </span>
                    <div className="post-frame16785">
                      <span className="post-text158">
                        <span>Dad Jokes</span>
                      </span>
                      <span className="post-text160">•</span>
                      <span className="post-text161">
                        <span>4.5k Followers</span>
                      </span>
                    </div>
                  </div>
                  <button className="post-button-primary3">
                    <div className="post-plussign2">
                      <div className="post-elements3">
                        <img
                          alt="VectorStrokeI134"
                          src="/external/vectorstrokei134-n3fl.svg"
                          className="post-vector-stroke2"
                        />
                      </div>
                    </div>
                  </button>
                </div>
                <div className="post-frame2329">
                  <div className="post-avatar08"></div>
                  <div className="post-frame95813">
                    <span className="post-text163">
                      <span>Cody Fisher</span>
                    </span>
                    <div className="post-frame16786">
                      <span className="post-text165">
                        <span>Dad Jokes</span>
                      </span>
                      <span className="post-text167">•</span>
                      <span className="post-text168">
                        <span>4.5k Followers</span>
                      </span>
                    </div>
                  </div>
                  <button className="post-button-primary4">
                    <div className="post-plussign3">
                      <div className="post-elements4">
                        <img
                          alt="VectorStrokeI134"
                          src="/external/vectorstrokei134-xn5n.svg"
                          className="post-vector-stroke3"
                        />
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="post-topbar">
          <div className="post-left2">
            <div className="post-hometab">
              <div className="post-logohorizontal">
                <img
                  alt="Gigglr0112I134"
                  src="/external/gigglr0112i134-c7ct-200h.png"
                  className="post-gigglr0112"
                />
                <span className="post-text170">
                  <span>gigglr</span>
                </span>
              </div>
            </div>
            <div className="post-tab">
              <div className="post-toptabitems">
                <div className="post-home05">
                  <div className="post-elements5">
                    <img
                      alt="SubtractI134"
                      src="/external/subtracti134-yr2.svg"
                      className="post-subtract"
                    />
                    <img
                      alt="Rectangle2332StrokeI134"
                      src="/external/rectangle2332strokei134-uhn6.svg"
                      className="post-rectangle2332-stroke"
                    />
                  </div>
                </div>
                <span className="post-text172">
                  <span>Home</span>
                </span>
                <img alt="xcloseI134" src className="post-xclose" />
              </div>
              <div className="post-toptabitems1">
                <div className="post-usersquare">
                  <div className="post-elements6">
                    <img
                      alt="SubtractI134"
                      src="/external/subtracti134-v73e.svg"
                      className="post-subtract1"
                    />
                  </div>
                </div>
                <span className="post-text174">
                  <span>Comedians</span>
                </span>
                <img alt="xcloseI134" src className="post-xclose1" />
              </div>
              <div className="post-toptabitems2">
                <div className="post-bot">
                  <div className="post-content3">
                    <img
                      alt="SubtractI134"
                      src="/external/subtracti134-dcsw.svg"
                      className="post-subtract2"
                    />
                    <img
                      alt="Vector7311StrokeI134"
                      src="/external/vector7311strokei134-r0h.svg"
                      className="post-vector7311-stroke"
                    />
                    <img
                      alt="Vector7312StrokeI134"
                      src="/external/vector7312strokei134-v369.svg"
                      className="post-vector7312-stroke"
                    />
                  </div>
                </div>
                <span className="post-text176">
                  <span>gigglr Chatbot</span>
                </span>
                <img alt="xcloseI134" src className="post-xclose2" />
              </div>
              <div className="post-toptabitems3">
                <div className="post-hotprice">
                  <div className="post-elements7">
                    <img
                      alt="SubtractI134"
                      src="/external/subtracti134-lti7.svg"
                      className="post-subtract3"
                    />
                  </div>
                </div>
                <span className="post-text178">
                  <span>Plans</span>
                </span>
                <img alt="xcloseI134" src className="post-xclose3" />
              </div>
            </div>
          </div>
          <div className="post-rightinfo">
            <div className="post-toptabitems4">
              <div className="post-uploadsquare01">
                <div className="post-elements8">
                  <img
                    alt="SubtractI134"
                    src="/external/subtracti134-o8b.svg"
                    className="post-subtract4"
                  />
                </div>
              </div>
              <span className="post-text180">
                <span>Upload</span>
              </span>
            </div>
            <div className="post-frame100000339910">
              <div className="post-message1">
                <div className="post-group08">
                  <img
                    alt="VectorI134"
                    src="/external/vectori134-6ukj.svg"
                    className="post-vector29"
                  />
                </div>
              </div>
            </div>
            <div className="post-frame1000003400">
              <div className="post-notification1">
                <div className="post-group09">
                  <img
                    alt="VectorI134"
                    src="/external/vectori134-zo8u.svg"
                    className="post-vector30"
                  />
                  <img
                    alt="Ellipse26I134"
                    src="/external/ellipse26i134-cka8-200h.png"
                    className="post-ellipse261"
                  />
                </div>
              </div>
            </div>
            <div className="post-profile">
              <div className="post-name">
                <div className="post-avatarnav1">
                  <div className="post-avatar09">
                    <img
                      alt="IMAGE151I134"
                      src="/external/image151i134-a2d-200h.png"
                      className="post-image1512"
                    />
                  </div>
                  <img
                    alt="Ellipse10I134"
                    src="/external/ellipse10i134-zshg-200h.png"
                    className="post-ellipse10"
                  />
                </div>
                <span className="post-text182">
                  <span>John Smith</span>
                </span>
              </div>
              <img
                alt="Vector8I134"
                src="/external/vector8i134-gnz.svg"
                className="post-vector8"
              />
            </div>
          </div>
        </div>
        <img
          alt="OverlayLight1341"
          src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7e3cb39-a737-46fd-b9f1-8537895fbe89/f26e7b20-834c-4f6a-9711-c3bb7260d029?org_if_sml=17596&amp;force_format=original"
          className="post-overlay-light"
        />
        <div className="post-post">
          <div className="post-elements-ui-card-card-header">
            <div className="post-icon">
              <div className="post-group10">
                <img alt="VectorI134" src className="post-vector31" />
                <img
                  alt="VectorI134"
                  src="/external/vectori134-0x8.svg"
                  className="post-vector32"
                />
              </div>
            </div>
            <span className="post-text184">
              <span>New Post</span>
            </span>
            <div className="post-icon1">
              <div className="post-group11">
                <img alt="VectorI134" src className="post-vector33" />
                <img
                  alt="VectorI134"
                  src="/external/vectori134-fvo.svg"
                  className="post-vector34"
                />
              </div>
            </div>
          </div>
          <div className="post-elements-margin"></div>
          <div className="post-frame1000003407">
            <div className="post-avatarnav2">
              <div className="post-avatar10">
                <img
                  alt="IMAGE151I134"
                  src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7e3cb39-a737-46fd-b9f1-8537895fbe89/4694fa70-7b81-4f66-b75a-100a03b413ed?org_if_sml=12812&amp;force_format=original"
                  className="post-image1513"
                />
              </div>
            </div>
            <div className="post-form-input-type">
              <img
                alt="globeI134"
                src="/external/globei134-gip9.svg"
                className="post-globe"
              />
              <span className="post-text186">
                <span>Anyone</span>
              </span>
              <div className="post-systemsarrowdownsline">
                <div className="post-group12">
                  <img alt="VectorI134" src className="post-vector35" />
                  <img
                    alt="VectorI134"
                    src="/external/vectori134-ih8e.svg"
                    className="post-vector36"
                  />
                </div>
              </div>
            </div>
            <div className="post-form-input-type1">
              <div className="post-communicationmessage2line">
                <div className="post-group13">
                  <img alt="VectorI134" src className="post-vector37" />
                  <img
                    alt="VectorI134"
                    src="/external/vectori134-xnri.svg"
                    className="post-vector38"
                  />
                </div>
              </div>
              <span className="post-text188">
                <span>Anyone</span>
              </span>
              <div className="post-systemsarrowdownsline1">
                <div className="post-group14 post-group14">
                  <img alt="VectorI134" src className="post-vector39" />
                  <img
                    alt="VectorI134"
                    src="/external/vectori134-okb.svg"
                    className="post-vector40"
                  />
                </div>
              </div>
            </div>
            <div className="post-form-input-type2">
              <div className="post-frame33">
                <div className="post-businesscopyrightline">
                  <div className="post-group15">
                    <img alt="VectorI134" src className="post-vector41" />
                    <img
                      alt="VectorI134"
                      src="/external/vectori134-wxwh.svg"
                      className="post-vector42"
                    />
                  </div>
                </div>
                <span className="post-text190 ButtonSmall">
                  <span>Copyright</span>
                </span>
              </div>
              <div className="post-frame331">
                <div className="post-systemsarrowdownsline2">
                  <div className="post-group16">
                    <img alt="VectorI134" src className="post-vector43" />
                    <img
                      alt="VectorI134"
                      src="/external/vectori134-rewp.svg"
                      className="post-vector44"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="Write something "
            className="post-elements-input"
          />
          <div className="post-frame1000003408">
            <button className="post-buttons3">
              <img
                alt="hashtagI134"
                src="/external/hashtagi134-ulx.svg"
                className="post-hashtag"
              />
            </button>
            <button className="post-buttons4">
              <img
                alt="atsymbolI134"
                src="/external/atsymboli134-p2vb.svg"
                className="post-atsymbol"
              />
            </button>
            <button className="post-buttons5">
              <img
                alt="locationmarkerI134"
                src="/external/locationmarkeri134-esih.svg"
                className="post-locationmarker"
              />
            </button>
          </div>
          <div className="post-elements-input1">
            <span className="post-text192">
              <span>
                Add to your post
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
            <div className="post-frame1000003455">
              <div className="post-businesscalendartodoline">
                <div className="post-group17">
                  <img alt="VectorI134" src className="post-vector45" />
                  <img
                    alt="VectorI134"
                    src="/external/vectori134-tsra.svg"
                    className="post-vector46"
                  />
                </div>
              </div>
              <div className="post-mediaimageaddline">
                <div className="post-group18">
                  <img alt="VectorI134" src className="post-vector47" />
                  <img
                    alt="VectorI134"
                    src="/external/vectori134-24im.svg"
                    className="post-vector48"
                  />
                </div>
              </div>
              <div className="post-mediavideoaddline">
                <div className="post-group19">
                  <img alt="VectorI134" src className="post-vector49" />
                  <img
                    alt="VectorI134"
                    src="/external/vectori134-yudi.svg"
                    className="post-vector50"
                  />
                </div>
              </div>
            </div>
            <button className="post-button-primary5">
              <span className="post-text194">
                <span> Post</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Post
