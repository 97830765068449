import React from 'react'

import { Helmet } from 'react-helmet'
import { Link,Redirect } from 'react-router-dom'
import './mallset.css'

const Mallset = (props) => {

  // if (window.screen.width > 700) {
  //   return <Redirect to='/allset'  />
  // }
  return (
    <div className="mallset-container">
      <Helmet>
        <title>Mallset - gigglr</title>
        <meta property="og:title" content="Mallset - gigglr" />
      </Helmet>
      <div className="mallset-youre-all-set">
        <div className="mallset-bar">
          <div className="mallset-appbar">
            <img
              alt="arrowbackoutlineI204"
              src="/external/arrowbackoutlinei204-emh9.svg"
              className="mallset-arrowbackoutline"
            />
          </div>
        </div>
        <div className="mallset-section">
          <div className="mallset-frame427319187">
            <div className="mallset-frame-basestepper">
              <div className="mallset-frame-basestepper01">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-qpxmg.svg"
                  className="mallset-essentialscheck"
                />
              </div>
            </div>
            <div className="mallset-frame-basestepper02">
              <div className="mallset-frame-basestepper03">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-cnc.svg"
                  className="mallset-essentialscheck1"
                />
              </div>
            </div>
            <div className="mallset-frame-basestepper04">
              <div className="mallset-frame-basestepper05">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-qv8e.svg"
                  className="mallset-essentialscheck2"
                />
              </div>
            </div>
            <div className="mallset-frame-basestepper06">
              <div className="mallset-frame-basestepper07">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-h3m1.svg"
                  className="mallset-essentialscheck3"
                />
              </div>
            </div>
            <div className="mallset-frame-basestepper08">
              <div className="mallset-frame-basestepper09">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-i8tg.svg"
                  className="mallset-essentialscheck4"
                />
              </div>
            </div>
            <div className="mallset-frame-dividerstepper">
              <img
                alt="DividerI204"
                src="/external/divideri204-x1gpm.svg"
                className="mallset-divider"
              />
            </div>
            <div className="mallset-frame-basestepper10">
              <div className="mallset-frame-basestepper11">
                <span className="mallset-text">6</span>
              </div>
            </div>
          </div>
          <div className="mallset-textandsupportingtext">
            <span className="mallset-text01">
              <span>Comedy Types</span>
            </span>
            <span className="mallset-text03">
              <span>Select comedy styles</span>
            </span>
          </div>
        </div>
        <div className="mallset-frame587">
          <div className="mallset-inputs">
            <div className="mallset-description">
              <span className="mallset-text05">
                <span>👍🏻</span>
              </span>
              <span className="mallset-text07">
                <span>You&apos;re All Set!</span>
              </span>
              <span className="mallset-text09">
                <span>
                  Your profile is now personalized and you&apos;re all set to
                  explore gigglr. Get ready to enjoy a world of comedy tailored
                  just for you!
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="mallset-ctasection">
          <button className="mallset-button-primary">
            <span className="mallset-text11">
            < Link to ="/mhome">Start Exploring</Link>
            </span>
          </button>
        </div>
        <img
          alt="NativeHomeIndicator2042"
          src="/external/nativehomeindicator2042-3g6.svg"
          className="mallset-native-home-indicator"
        />
      </div>
    </div>
  )
}

export default Mallset
