import React,{useState} from 'react'
import axios from "axios";
import { Helmet } from 'react-helmet'

import './mforgotpass.css'
import { Link,Redirect } from 'react-router-dom'
const Mforgotpass = (props) => {
  // if (window.screen.width > 700) {
  //   return <Redirect to='/forgotpass'  />
  // }

  const [email, setEmail] = useState("");
	const [msg, setMsg] = useState("");
	const [error, setError] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const url = `https://api.gigglr.net/api/password-reset`;
			const { data } = await axios.post(url, { email });
			setMsg(data.message);
			setError("");
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
				setMsg("");
			}
		}
	};


  return (
    <div className="mforgotpass-container">
      <Helmet>
        <title>Mforgotpass - gigglr</title>
        <meta property="og:title" content="Mforgotpass - gigglr" />
      </Helmet>
      <form className="mforgotpass-forgot-password" onSubmit={handleSubmit}>
        <div className="mforgotpass-appbar">
          <Link to = "/">
          <img
            alt="arrowbackoutline2042"
            src="/external/arrowbackoutline2042-bgec.svg"
            className="mforgotpass-arrowbackoutline"
          />
          </Link>
        </div>
        <img
          alt="NativeHomeIndicator2042"
          src="/external/nativehomeindicator2042-v8bg.svg"
          className="mforgotpass-native-home-indicator"
        />
        <div className="mforgotpass-frame587">
          <div className="mforgotpass-frame1">
            <span className="mforgotpass-text">
              <span>Forgot Password</span>
            </span>
            <span className="mforgotpass-text02">
              <span>
                Enter the username or email address associated with your gigglr
                account. We&apos;ll send you an email with instructions to reset
                your password.
              </span>
            </span>
          </div>
          <div className="mforgotpass-frame588">
            <div className="mforgotpass-input">
              <div className="mforgotpass-frame562">
                <span className="mforgotpass-text04">
                  <span>Email</span>
                </span>
                <span className="mforgotpass-text06">
                  <span>Optional</span>
                </span>
              </div>
              <div className="mforgotpass-frame469">
                {/* <span className="mforgotpass-text08">
                  <span>hi@email.com</span>
                </span> */}
                <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                className="mforgotpass-text08"
				     />
              </div>
            </div>
          </div>
          <button type ="submit" className="mforgotpass-buttons">
            <button className="mforgotpass-button-primary">
              <span className="mforgotpass-text10">
                <span>Send Reset Link</span>
              </span>
            </button>
          </button>
        </div>
      </form>
    </div>
  )
}

export default Mforgotpass
