import React from 'react'

import { Helmet } from 'react-helmet'

import './monboard5.css'

const Monboard5 = (props) => {
  return (
    <div className="monboard5-container">
      <Helmet>
        <title>Monboard5 - gigglr</title>
        <meta property="og:title" content="Monboard5 - gigglr" />
      </Helmet>
      <div className="monboard5-onboardingstep7">
        <div className="monboard5-bar">
          <div className="monboard5-appbar">
            <img
              alt="arrowbackoutlineI204"
              src="/external/arrowbackoutlinei204-ha9.svg"
              className="monboard5-arrowbackoutline"
            />
          </div>
        </div>
        <div className="monboard5-section">
          <div className="monboard5-frame427319187">
            <div className="monboard5-frame-basestepper">
              <div className="monboard5-frame-basestepper01">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-b2cm.svg"
                  className="monboard5-essentialscheck"
                />
              </div>
            </div>
            <div className="monboard5-frame-basestepper02">
              <div className="monboard5-frame-basestepper03">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-zcf5.svg"
                  className="monboard5-essentialscheck1"
                />
              </div>
            </div>
            <div className="monboard5-frame-basestepper04">
              <div className="monboard5-frame-basestepper05">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-t0b.svg"
                  className="monboard5-essentialscheck2"
                />
              </div>
            </div>
            <div className="monboard5-frame-basestepper06">
              <div className="monboard5-frame-basestepper07">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-hqt.svg"
                  className="monboard5-essentialscheck3"
                />
              </div>
            </div>
            <div className="monboard5-frame-basestepper08">
              <div className="monboard5-frame-basestepper09">
                <span className="monboard5-text">5</span>
              </div>
            </div>
            <div className="monboard5-frame-dividerstepper">
              <img
                alt="DividerI204"
                src="/external/divideri204-xuqe.svg"
                className="monboard5-divider"
              />
            </div>
            <div className="monboard5-frame-basestepper10">
              <div className="monboard5-frame-basestepper11">
                <span className="monboard5-text01">6</span>
              </div>
            </div>
          </div>
          <div className="monboard5-textandsupportingtext">
            <span className="monboard5-text02">
              <span>Comedy Types</span>
            </span>
            <span className="monboard5-text04">
              <span>Select comedy styles</span>
            </span>
          </div>
        </div>
        <div className="monboard5-frame587">
          <div className="monboard5-inputs">
            <div className="monboard5-description">
              <span className="monboard5-text06">
                <span>Step 5</span>
              </span>
              <span className="monboard5-text08">
                <span>Choose Comedy Types</span>
              </span>
              <span className="monboard5-text10">
                <span>
                  What kind of comedy do you enjoy? Pick as many as you like!
                </span>
              </span>
            </div>
            <div className="monboard5-frame1">
              <button className="monboard5-ghostbutton">
                <span className="monboard5-text12">
                  <span>Stand-Up</span>
                </span>
              </button>
              <button className="monboard5-button-primary">
                <span className="monboard5-text14">
                  <span>Satire</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton01">
                <span className="monboard5-text16">
                  <span>Slapstick</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton02">
                <span className="monboard5-text18">
                  <span>Dark Humor</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton03">
                <span className="monboard5-text20">
                  <span>Sarcasm</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton04">
                <span className="monboard5-text22">
                  <span>Parody</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton05">
                <span className="monboard5-text24">
                  <span>Romantic Comedy</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton06">
                <span className="monboard5-text26">
                  <span>Observational</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton07">
                <span className="monboard5-text28">
                  <span>Political</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton08">
                <span className="monboard5-text30">
                  <span>Memes</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton09">
                <span className="monboard5-text32">
                  <span>Improv</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton10">
                <span className="monboard5-text34">
                  <span>Sketch</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton11">
                <span className="monboard5-text36">
                  <span>Physical Comedy</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton12">
                <span className="monboard5-text38">
                  <span>Absurdist</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton13">
                <span className="monboard5-text40">
                  <span>Musical Comedy</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton14">
                <span className="monboard5-text42">
                  <span>Historical</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton15">
                <span className="monboard5-text44">
                  <span>Cringe Comedy</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton16">
                <span className="monboard5-text46">
                  <span>Science Humor</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton17">
                <span className="monboard5-text48">
                  <span>Word Play</span>
                </span>
              </button>
              <button className="monboard5-ghostbutton18">
                <span className="monboard5-text50">
                  <span>Cult Classic</span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="monboard5-ctasection">
          <button className="monboard5-ghostbutton19">
            <span className="monboard5-text52">
              <span>Back</span>
            </span>
          </button>
          <button className="monboard5-button-primary1">
            <span className="monboard5-text54">
              <span>Finish</span>
            </span>
          </button>
        </div>
        <img
          alt="NativeHomeIndicator2042"
          src="/external/nativehomeindicator2042-axfj.svg"
          className="monboard5-native-home-indicator"
        />
      </div>
    </div>
  )
}

export default Monboard5
