import React,{useState,useContext} from 'react'
import { Link, Redirect, useHistory  } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import axios from'axios'
// import {store} from '../index';
import { GoogleLogin } from 'react-google-login';
// refresh token
import { refreshTokenSetup } from './utils/refreshToken';

const clientId =
  '707788443358-u05p46nssla3l8tmn58tpo9r5sommgks.apps.googleusercontent.com';
//

import './register.css'



const Register = (props) => {

  if (window.screen.width < 700) {
    return <Redirect to='/mregister'  />
  }
  
  const [data, setData] = useState({
	//	firstName: "",
	//	lastName: "",
    username:"",
		email: "",
		password: "",
	});
	const [error, setError] = useState("");
	const [msg, setMsg] = useState("");

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			//  const url = "http://localhost:8090/api/users";
      const url = "https://api.gigglr.net/api/users";
			const { data: res } = await axios.post(url, data);
			setMsg(res.message);
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}
		}
	};

  // //
  // let navigate = useHistory();
  // const [token,setToken] = useContext(store)
  // const [register,setRegister]=useState({
  //  username:'',
  //  email:'',
  //  password:'',
  //   // confirmpassword:''
  // })
  // const changeHandler=(e)=>{
  //   setRegister({...register,[e.target.name]:e.target.value})
  // }
  // const submitHandler=(e)=>{
  //   e.preventDefault()
  //   console.log(register)
  //   axios.post('https://api.gigglr.net/register',register).then(
  //     // res=>{alert(res.data);
  //     // setRegister({
  //     //   username:'',
  //     //   email:'',
  //     //   password:'',
  //     //   // confirmpassword:''
  //     // })}
      
  //      res=>{setToken(res.data.token);
  //     setRegister({
  //      username:'', 
  //       email:'',
  //      password:'',
  //       // confirmpassword:''
  //     })}
      
  //   )
  //   .then(
  //     navigate.push("/onboard1")
  //   )
  //   if(token){
  //     return <Redirect to='/onboard1' />
  //  }
  // }



  /////google
  // const onSuccess = (res) => {
  //   console.log('Login Success: currentUser:', res.profileObj);
  //   alert(
  //     `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
  //   );
  //   refreshTokenSetup(res);
  // };

  // const onFailure = (res) => {
  //   // console.log('Login failed: res:', res);
  //   // alert(
  //   //   `Failed to login. 😢 Please ping this to admin`
  //   // );
  //   navigate.push("/sign-in1")
  // };
  // ///


///
  return (
    <div className="register-container">
      <Helmet>
        <title>Register </title>
        <meta property="og:title" content="Register " />
      </Helmet>
      {/* <Link to ="/sign-in1"> */}
      <button className="register-container1">
      <Link to ="/sign-in1">
        <span className="register-ghostbutton">
           {/* <Link to ="/sign-in1"> */}
          <span className="register-text">
            <span>Sign In</span>
          </span>
          {/* </Link> */}
          <img
            alt="login03I134"
            src="/external/login03i134-qyh7.svg"
            className="register-login03"
          />
         
        </span>
        </Link>
      </button>
      {/* </Link> */}
      <div className="register-container2">
        <div className="register-container3">
          <img
            alt="bg1349"
            src="/external/rectangle23357517-d7r-600w.png"
            className="register-bg"
          />
        </div>
        {/* <form  onSubmit={handleSubmit}className="register-container4"> */}
        <div  className="register-container4">
          <div className="register-gigglr0111">
            <img
              alt="Gigglr0111I134"
              src="/external/gigglr0111i134-s3yh-200w.png"
              className="register-gigglr01111"
            />
          </div>
          <div className="register-frame1">
            <span className="register-text02">
              <span>Welcome to gigglr</span>
            </span>
            <span className="register-text04">
              <span>Where Laughter Lives</span>
            </span>
          </div>
          <div className="register-openapp">
            <form className="register-button" action="https://api.gigglr.net/auth/google/callback">
            {/* <form className="register-button" action="http://localhost:8090/auth/google/callback"> */}
              <img
                alt="Loginlogo1348"
                src="/external/loginlogo1348-6b47.svg"
                className="register-loginlogo"
              />
              {/* <GoogleLogin
                      clientId={clientId}
                      buttonText="Continue with Google"
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      cookiePolicy={'single_host_origin'}
                      style={{ marginTop: '100px' }}
                      isSignedIn={true}
                    /> */}
              <button className="register-text06">
                <span>Continue with Google</span>
              </button>
            </form>
            <form className="register-button1" action="http://3.144.71.26:8090/auth/facebook/callback" >
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="register-icon"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
              <span className="register-text08">Continue with Facebook</span>
            </form>
          </div>
          <div className="register-frame590">
            <img
              alt="Rectangle13131348"
              src="/external/rectangle13131348-7ejn-200h.png"
              className="register-rectangle1313"
            />
            <span className="register-text09">
              <span>Or</span>
            </span>
            <img
              alt="Rectangle13141348"
              src="/external/rectangle13141348-0aeg-200h.png"
              className="register-rectangle1314"
            />
          </div>
          <form onSubmit={handleSubmit} className="register-frame588">
            <div className="register-input">
              <div className="register-frame562">
                <span className="register-text11">
                  <span>Choose username</span>
                </span>
                <span className="register-text13">
                  <span>Optional</span>
                </span>
              </div>
              <div className="register-frame469">
                {/* <span className="register-text15"> */}
                <input type='text' className="register-text15" placeholder='Enter Username'name='username' value={data.username} onChange={handleChange}/>
                {/* </span> */}
              </div>
            </div>
            <div className="register-input1">
              <div className="register-frame5621">
                <span className="register-text17">
                  <span>Email</span>
                </span>
                <span className="register-text19">
                  <span>Optional</span>
                </span>
              </div>
              <div className="register-frame4691">
                {/* <span className="register-text21"> */}
                <input type='text' className="register-text21"placeholder='Enter Email'name='email' value={data.email} onChange={handleChange}/> 
                {/* </span> */}
              </div>
            </div>
            <div className="register-input2">
              <div className="register-frame5622">
                <span className="register-text23">
                  <span>Password</span>
                </span>
                <span className="register-text25">
                  <span>Optional</span>
                </span>
              </div>
              <div className="register-frame4692">
                {/* <span className="register-text27"> */}
                <input type='text' className="register-text27" placeholder='Enter Password' name='password' value={data.password} onChange={handleChange}/>
                {/* </span> */}
              </div>
              <span className="register-text29">
                <span>At least 12 characters</span>
              </span>
            </div>
            {error && <div style={{color:'red'}} >{error}</div>}
						{msg && <div style={{color:'red'}} >{msg}</div>}

            <button type="submit" className="register-buttons">
            <button className="register-button-primary">
              <span className="register-text31">
                <span>Join gigglr</span>
              </span>
            </button>
            <span className="register-text33">
              <span className="register-text34">
                By creating an account, you agree to our
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>Terms of Service</span>
              <span className="register-text36">
                {' '}
                and
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>Privacy &amp; Cookie Statement.</span>
            </span>
          </button>


          </form>
       

          {/* <button type="submit" className="register-buttons">
            <button className="register-button-primary">
              <span className="register-text31">
                <span>Join gigglr</span>
              </span>
            </button>
            <span className="register-text33">
              <span className="register-text34">
                By creating an account, you agree to our
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>Terms of Service</span>
              <span className="register-text36">
                {' '}
                and
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>Privacy &amp; Cookie Statement.</span>
            </span>
          </button> */}

        {/* </form> */}
      </div>
      </div>
    </div>
  )
}

export default Register
