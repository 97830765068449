import React from 'react'

import { Helmet } from 'react-helmet'
import { Link,Redirect } from 'react-router-dom'
import './monboard2.css'

const Monboard2 = (props) => {
  if (window.screen.width > 710) {
    return <Redirect to='/onboard2'  />
  }
  return (
    <div className="monboard2-container">
      <Helmet>
        <title>Monboard2 - gigglr</title>
        <meta property="og:title" content="Monboard2 - gigglr" />
      </Helmet>
      <div className="monboard2-onboardingstep2">
        <div className="monboard2-bar">
          <div className="monboard2-appbar">
          <Link to= "/monboard1">
            <img
              alt="arrowbackoutlineI204"
              src="/external/arrowbackoutlinei204-pyns.svg"
              className="monboard2-arrowbackoutline"
            />
            </Link>
          </div>
        </div>
        <div className="monboard2-section">
          <div className="monboard2-frame427319187">
            <div className="monboard2-frame-basestepper">
              <div className="monboard2-frame-basestepper01">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-xd.svg"
                  className="monboard2-essentialscheck"
                />
              </div>
            </div>
            <div className="monboard2-frame-basestepper02">
              <div className="monboard2-frame-basestepper03">
                <span className="monboard2-text">2</span>
              </div>
            </div>
            <div className="monboard2-frame-dividerstepper">
              <img
                alt="DividerI204"
                src="/external/divideri204-s45c.svg"
                className="monboard2-divider"
              />
            </div>
            <div className="monboard2-frame-basestepper04">
              <div className="monboard2-frame-basestepper05">
                <span className="monboard2-text01">3</span>
              </div>
            </div>
            <div className="monboard2-frame-basestepper06">
              <div className="monboard2-frame-basestepper07">
                <span className="monboard2-text02">4</span>
              </div>
            </div>
            <div className="monboard2-frame-basestepper08">
              <div className="monboard2-frame-basestepper09">
                <span className="monboard2-text03">5</span>
              </div>
            </div>
            <div className="monboard2-frame-basestepper10">
              <div className="monboard2-frame-basestepper11">
                <span className="monboard2-text04">6</span>
              </div>
            </div>
          </div>
          <div className="monboard2-textandsupportingtext">
            <span className="monboard2-text05">
              <span>Your Location</span>
            </span>
            <span className="monboard2-text07">
              <span>Tailor your content.</span>
            </span>
          </div>
        </div>
        <div className="monboard2-frame587">
          <div className="monboard2-inputs">
            <div className="monboard2-description">
              <span className="monboard2-text09">
                <span>Step 2</span>
              </span>
              <span className="monboard2-text11">
                <span>Choose Your Location</span>
              </span>
              <span className="monboard2-text13">
                <span>
                  Let us know where you&apos;re located to get region-specific
                  jokes and comedians.
                </span>
              </span>
            </div>
            <div className="monboard2-input">
              <div className="monboard2-frame562">
                <span className="monboard2-text15">
                  <span>Choose username</span>
                </span>
                <span className="monboard2-text17">
                  <span>Optional</span>
                </span>
              </div>
              <select className="monboard2-frame469">
                {/* <span className="monboard2-text19">
                  <span>e.g. New York, USA</span>
                </span> */}
                <option className="monboard2-text19">
                    New-York USA
                  </option>
                  <option className="monboard2-text19">
                    California USA
                  </option>
                  <option className="monboard2-text19">
                    Philadelphia USA
                  </option>
              </select>
            </div>
          </div>
        </div>
        <div className="monboard2-ctasection">
          <button className="monboard2-ghostbutton">
            <span className="monboard2-text21">
            <Link to = "/monboard1">Back</Link>
            </span>
          </button>
          <button className="monboard2-button-primary">
            <span className="monboard2-text23">
            <Link to = "/monboard3">Next</Link>
            </span>
          </button>
        </div>
        <img
          alt="NativeHomeIndicator2042"
          src="/external/nativehomeindicator2042-h295.svg"
          className="monboard2-native-home-indicator"
        />
      </div>
    </div>
  )
}

export default Monboard2
