import React from 'react'
import { Link,Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import './monboard4.css'

const Monboard4 = (props) => {
  // if (window.screen.width > 700) {
  //   return <Redirect to='/onboard4'  />
  // }
  return (
    <div className="monboard4-container">
      <Helmet>
        <title>Monboard4 - gigglr</title>
        <meta property="og:title" content="Monboard4 - gigglr" />
      </Helmet>
      <div className="monboard4-onboardingstep4">
        <div className="monboard4-bar">
          <div className="monboard4-appbar">
            <img
              alt="arrowbackoutlineI204"
              src="/external/arrowbackoutlinei204-r4p6.svg"
              className="monboard4-arrowbackoutline"
            />
          </div>
        </div>
        <div className="monboard4-section">
          <div className="monboard4-frame427319187">
            <div className="monboard4-frame-basestepper">
              <div className="monboard4-frame-basestepper01">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-gq9.svg"
                  className="monboard4-essentialscheck"
                />
              </div>
            </div>
            <div className="monboard4-frame-basestepper02">
              <div className="monboard4-frame-basestepper03">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-0fa33.svg"
                  className="monboard4-essentialscheck1"
                />
              </div>
            </div>
            <div className="monboard4-frame-basestepper04">
              <div className="monboard4-frame-basestepper05">
                <img
                  alt="EssentialscheckI204"
                  src="/external/essentialschecki204-jh6n.svg"
                  className="monboard4-essentialscheck2"
                />
              </div>
            </div>
            <div className="monboard4-frame-basestepper06">
              <div className="monboard4-frame-basestepper07">
                <span className="monboard4-text">4</span>
              </div>
            </div>
            <div className="monboard4-frame-dividerstepper">
              <img
                alt="DividerI204"
                src="/external/divideri204-6oh.svg"
                className="monboard4-divider"
              />
            </div>
            <div className="monboard4-frame-basestepper08">
              <div className="monboard4-frame-basestepper09">
                <span className="monboard4-text01">5</span>
              </div>
            </div>
            <div className="monboard4-frame-basestepper10">
              <div className="monboard4-frame-basestepper11">
                <span className="monboard4-text02">6</span>
              </div>
            </div>
          </div>
          <div className="monboard4-textandsupportingtext">
            <span className="monboard4-text03">
              <span>Comedy Ratings</span>
            </span>
            <span className="monboard4-text05">
              <span>Choose joke ratings.</span>
            </span>
          </div>
        </div>
        <div className="monboard4-frame587">
          <div className="monboard4-inputs">
            <div className="monboard4-description">
              <span className="monboard4-text07">
                <span>Step 4</span>
              </span>
              <span className="monboard4-text09">
                <span>Set Comedy Ratings</span>
              </span>
              <span className="monboard4-text11">
                <span>Choose the ratings that suit your comedy taste.</span>
              </span>
            </div>
            <div className="monboard4-chatcreativity">
              <div className="monboard4-frame110">
                <span className="monboard4-text13">
                  <span>Comedy Ratings</span>
                </span>
                <img
                  alt="infocircle2042"
                  src="/external/infocircle2042-0d6g.svg"
                  className="monboard4-infocircle"
                />
              </div>
              <div className="monboard4-toggle">
                <div className="monboard4-toggleitem">
                  <span className="monboard4-text15">
                    <span>G-rated</span>
                  </span>
                </div>
                <div className="monboard4-toggleitem1">
                  <span className="monboard4-text17">
                    <span>PG</span>
                  </span>
                </div>
                <div className="monboard4-toggleitem2">
                  <span className="monboard4-text19">
                    <span>PG-13</span>
                  </span>
                </div>
                <div className="monboard4-toggleitem3">
                  <span className="monboard4-text21">
                    <span>Adult</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="monboard4-ctasection">
          <button className="monboard4-ghostbutton">
            <span className="monboard4-text23">
              <span>Back</span>
            </span>
          </button>
          <button className="monboard4-button-primary">
            <span className="monboard4-text25">
              <span>Next</span>
            </span>
          </button>
        </div>
        <img
          alt="NativeHomeIndicator2042"
          src="/external/nativehomeindicator2042-zxao.svg"
          className="monboard4-native-home-indicator"
        />
      </div>
    </div>
  )
}

export default Monboard4
